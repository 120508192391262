import React, { useEffect } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';

const Brain_title = () => {

const [ref, inView] = useInView({ triggerOnce: true });
const controllogo = useAnimation();  

useEffect(() => {
    if (inView) {
        controllogo.start({
            y:0,
            opacity:1,
            transition:{delay:0.4, duration:0.8, type:"spring"}
        });
    }
}, [controllogo, inView]);

  return (
    <motion.div 
    ref={ref}
    initial={{ y:-100, opacity:0 }}
    animate={controllogo}
    className="title-div shadow-md">
          <div className="circle-1"></div>
          <div className="circle-2"></div>
          <h1>BrainBlog</h1>
    </motion.div>
  )
}

export default Brain_title
