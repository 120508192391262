import React, { useEffect } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
const Locations = () => {
  const {t} = useTranslation();
  var text = [t("AboutUs.mexico-address"), t("AboutUs.london-address")];
    const [ref, inView] = useInView({ triggerOnce: true });
    const controlmxImage = useAnimation();
    const controlmxlocation = useAnimation();
    const controlukImage = useAnimation();
    const controluklocation = useAnimation();

    useEffect(() => {
      if (inView) {
        controlmxImage.start({
          x:0,
          opacity:1,
          transition: {duration:1}
        });
        controlmxlocation.start({
          scale: 1,
          x:0,
          opacity:1,
          transition: {delay:0.3, duration:1}
        });
        controlukImage.start({
            x:0,
            opacity:1,
            transition: {delay:0.6, duration:1}
          });
        controluklocation.start({
            scale: 1,
            x:0,
            opacity:1,
            transition: {delay:0.9, duration:1}
          });
      }
    },[controlmxImage,controlmxlocation,controlukImage,controluklocation,inView]);

  return (
    <div className='w-full overflow-hidden'>
      <div className="location-div flex gap-20 pr-10">
        <motion.div 
        ref={ref}
        initial={{x:-50, opacity:0}}
        animate={controlmxImage}
        className="image-container shadow-md relative flex-1">
          <img src={text[0]}/>
        </motion.div>
        <motion.div 
        ref={ref}
        initial={{x:50, opacity:0, scale:0.5}}
        animate={controlmxlocation}
        className="map-container rounded-md shadow-md flex-1">
          <iframe className="rounded-xl" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.991717322125!2d-99.17046472438048!3d19.41276384131335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff40a0efc1e3%3A0x980025f771218013!2sAv%20M%C3%A9xico%20175-departamento%20101%2C%20Hip%C3%B3dromo%2C%20Cuauht%C3%A9moc%2C%2006100%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX%2C%20Mexico!5e0!3m2!1sen!2sie!4v1704955317135!5m2!1sen!2sie" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </motion.div>
        </div>
      

      <div className="location-div mt-20 mb-20 gap-20 pr-10 flex">
      <motion.div 
        ref={ref}
        initial={{x:-50, opacity:0}}
        animate={controlukImage}
        className="image-container shadow-md relative flex-1">
          <img src={text[1]}/>
        </motion.div>
        <motion.div 
        ref={ref}
        initial={{x:50, opacity:0, scale:0.5}}
        animate={controluklocation}
         className="map-container rounded-md shadow-md flex-1">
        <iframe className="rounded-xl" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.5189318448283!2d-0.059928923281926984!3d51.540382808293174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ce6120d51e1%3A0x2edbe43ac0d1d5e3!2s1%20Mentmore%20Terrace%2C%20London%20E8%203PN%2C%20UK!5e0!3m2!1sen!2sie!4v1702592844679!5m2!1sen!2sie" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </motion.div>
      </div>
    </div>
  )
}

export default Locations
