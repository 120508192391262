import React, { useEffect, useState } from 'react'
import { firebase } from "../../FirebaseConfig"
import { motion } from "framer-motion";
import PartnerPanel from './PartnerPanel';
import HomePageTestimonials from './HomePageTestimonials';
import BrainNewsVideosPanel from './BrainNewsVideosPanel';
import SerViceTestimonials from './ServiceTestimonials';
import BrainBlogs from './BrainBlogs';
import BrainNewsTitleImages from './BrainNewsTitleImages';
import SocialLinks from './SocialLinks';
import Emailmessages from './Emailmessages';

const LoginPage = () => {

  const [showBlogs, setBlogs] = useState(false);
  const [showLinks, setLinks] = useState(false);
  const [showBrainImages, setBrainImages] = useState(false);
  const [showPartnersPanel, setPartnersPanel] = useState(false)
  const [showHomeTestimonialsPanel, setHomeTestimonialsPanel] = useState(false)
  const [showBrainVideos, setBrainVideos] = useState(false)
  const [showServiceTests, setServiceTests] = useState(false)
  const [showMessages, setMessages] = useState(false)


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState("");

  useEffect(() => {
    const subscriber = firebase.auth().onAuthStateChanged(onAuthChanged);
    return subscriber;
  },[]);
  

  const handleForgotPassword = async () => {
    try {
      // Validate if the email is registered
      // You may need to check if the email exists in your database
  
      const email = prompt("Enter your email address:");
      if (email) {
        await firebase.auth().sendPasswordResetEmail(email);
        alert("Password reset email sent");
      }
    } catch (error) {
      console.error("Error sending password reset email", error);
      alert("Failed to send password reset email. Please check your email address.");
    }
  };

  function toggleMessages(){
    setMessages(!showMessages);

    setHomeTestimonialsPanel(false);
    setBrainVideos(false);
    setPartnersPanel(false);
    setBlogs(false);
    setServiceTests(false);
    setBrainImages(false);
    setLinks(false);
  }


  function toggleSocialLinks(){
    setLinks(!showLinks);

    setHomeTestimonialsPanel(false);
    setBrainVideos(false);
    setPartnersPanel(false);
    setBlogs(false);
    setMessages(false);
    setServiceTests(false);
    setBrainImages(false);
  }

  function toggleBrainImages(){
    setBrainImages(!showBrainImages);

    setHomeTestimonialsPanel(false);
    setBrainVideos(false);
    setPartnersPanel(false);
    setMessages(false);
    setBlogs(false);
    setServiceTests(false);
    setLinks(false);
  }

  function toggleServiceTests(){
    setServiceTests(!showServiceTests);

    setHomeTestimonialsPanel(false);
    setMessages(false);
    setBrainVideos(false);
    setBrainImages(false);
    setLinks(false);
    setPartnersPanel(false);
    setBlogs(false);
  }

  function toggleBlogsPanel(){
    setBlogs(!showBlogs);

    setHomeTestimonialsPanel(false);
    setMessages(false);
    setBrainVideos(false);
    setServiceTests(false);
    setLinks(false);
    setBrainImages(false);
    setPartnersPanel(false);
  }

  function togglePartnerPanel(){
    setPartnersPanel(!showPartnersPanel);

    setHomeTestimonialsPanel(false);
    setBrainVideos(false);
    setLinks(false);
    setServiceTests(false);
    setBrainImages(false);
    setMessages(false);
    setBlogs(false);
  }

  function toggleBrainNewsVideosPanel(){
    setBrainVideos(!showBrainVideos);

    setHomeTestimonialsPanel(false);
    setBrainImages(false);
    setLinks(false);
    setServiceTests(false);
    setMessages(false);
    setPartnersPanel(false);
    setBlogs(false);
  }

  function toggleHomeTestimonialsPanel()
  {
    setHomeTestimonialsPanel(!showHomeTestimonialsPanel);

    setPartnersPanel(false);
    setBrainVideos(false);
    setServiceTests(false);
    setBrainImages(false);
    setMessages(false);
    setBlogs(false);
    setLinks(false);
  }

  function onAuthChanged(user)
    {
        setUser(user);
        // To do when user is logged in
    }

    const loginButtonHandler = async (e) => {
      try {
        e.preventDefault();
    
        // Proceed with the login
        
    
        // Set persistence to LOCAL only if "Remember Me" is checked
        const rememberMeCheckbox = document.getElementById("rememberMe");
        if (rememberMeCheckbox.checked) {
          console.log("Checked");
          await firebase.auth().setPersistence('local');
        } else {
          console.log("Unchecked");
          await firebase.auth().setPersistence('session');
        }

        await firebase.auth().signInWithEmailAndPassword(email, password);
    
        console.log("Login Successful");
      } catch (error) {
        console.error("Login Failed", error);
        alert(`Invalid email or password. ${error}`);
      }
      setEmail("");
      setPassword("");
    };
    

  const logoutButtonHandler = async (e) => {
    e.preventDefault();

    try{
      await firebase.auth().signOut().then(() => {
        console.log('Logged out')
      });
    }catch(e){
      console.log(e);
    }
  } 

  if(user)
  {
    return(<div className='flex flex-col justify-center items-center mb-10'>
      <motion.div 
      initial={{ y:-100, opacity:0 }}
      animate={{ y:0, opacity:1, transition:{type: "spring"}}}
      className="title-div w-fit shadow-md">
          <div className="circle-1"></div>
          <div className="circle-2"></div>
          <h1>Control Panel</h1>
      </motion.div>
      <p className='m-5'>{user.email}</p>
      <button onClick={logoutButtonHandler} className="m-5 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300">
            Logout
      </button>

      {/* Home Partner Changable */}
      <div className='home-partner-panel-container'>
        <div className='flex gap-5 mx-5'>
        <button onClick={togglePartnerPanel} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300">Show Partners</button>
        <button onClick={toggleHomeTestimonialsPanel} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300">Show Home Testimonials</button>
        <button onClick={toggleBrainNewsVideosPanel} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300">Show BrainNews Videos</button>
        <button onClick={toggleServiceTests} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300">Show Service Testimonials</button>
        </div>
        <div className="flex gap-5 m-5">
        <button onClick={toggleBlogsPanel} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300">Show Brain Blogs</button>
        <button onClick={toggleBrainImages} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300">Show Brain Title Images</button>
        <button onClick={toggleSocialLinks} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300">Show Social Media</button>
        <button onClick={toggleMessages} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300">Show Email Messages</button>
        </div>
        {showPartnersPanel && <PartnerPanel />}
        {showHomeTestimonialsPanel && <HomePageTestimonials />}
        {showBrainVideos && <BrainNewsVideosPanel />}
        {showServiceTests && <SerViceTestimonials />}
        {showBlogs && <BrainBlogs />}
        {showBrainImages && <BrainNewsTitleImages />}
        {showLinks && <SocialLinks />}
        {showMessages && <Emailmessages />}
      </div> 
    </div>)
  }

    return (
      <div className="bg-gray-100 h-screen flex items-center justify-center">
        <div className="bg-white p-8 rounded shadow-md w-96">
          <h1 className="text-2xl font-semibold mb-4">Login</h1>
  
          {/* Login Form */}
          <form>
            {/* Email Address */}
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-600">Email Address</label>
              <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" id="email" name="email" className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300" />
            </div>
  
            {/* Password */}
            <div className="mb-4">
              <label htmlFor="password" className="block text-sm font-medium text-gray-600">Password</label>
              <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300" />
            </div>
  
            {/* Remember Me Checkbox */}
            <div className="flex items-center mb-4">
            <input type="checkbox" id="rememberMe" name="rememberMe" className="h-4 w-4 text-blue-500" />
              <label htmlFor="rememberMe" className="ml-2 text-sm text-gray-600">Remember me</label>
            </div>
  
            {/* Forgot Password Link */}
            <div className="text-sm text-blue-500 mb-4">
              <a onClick={handleForgotPassword} className="hover:underline">Forgot Password?</a>
            </div>
  
            {/* Login Button */}
            <button onClick={loginButtonHandler} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300">
              Login
            </button>
          </form>
            {/* Forgot Password Modal */}
            
        </div>
      </div>
    );
  };
export default LoginPage


