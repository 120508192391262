import Hero_main from "./Hero_main";
import Home_services from "./Home_services";
import Home_partners from "./Home_partners";
import Hero_testimonials from "./Hero_testimonials";
import Home_location from "./Home_location";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import ReactGA from "react-ga";
export default function Home() {
  const { i18n } = useTranslation();
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname)
  // });
  const videoSrc = i18n.language === 'es' ? './videos/homeVideo2.webm' : './videos/homeVideo.webm';
  return (
    <div className="HomePage overflow-hidden">
    {/* HomePage Hero Section */}
    <Hero_main/>

    {/* Video Section */}
    <div className="video-container w-full">
          <video className="w-full" src={videoSrc} autoPlay loop controls muted></video>
    </div>

    {/* Our Services */}
    <Home_services/>

    {/* Partners */}
    <Home_partners />

    {/* Reviews */}
    <Hero_testimonials/>
    
    {/* Location Div */}
    <Home_location/>
    </div>
  )
}


