import React, { useEffect, useState } from 'react'
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';


const Home_services = () => {
  const {t} = useTranslation();
  var translation = [t('Index.home-services-1-title'), t('Index.home-services-2-title'), t('Index.home-services-3-title') ,t('Index.home-services-4-title'), t('Index.home-services-5-title'), t('Index.home-services-h1'), t('Index.home-services-viewmore'), 
  t('Index.view-more-1'), t('Index.view-more-2'), t('Index.view-more-3'), t('Index.view-more-4'), t('Index.view-more-5')]
  const [ref, inView] = useInView({ triggerOnce: true });
  const [ref1, inView1] = useInView({ triggerOnce: true });
  const controls = useAnimation();
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const controls4 = useAnimation();
  const controls5 = useAnimation();
  const mobileVariants = {
    hidden: { opacity: 0, x: -50 }, // Start from the left side
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const updateIsMobile = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  useEffect(() => {
        if (inView) {
          if(isMobile){
            // Mobile animations (coming from the side)
          controls.start({ opacity: 1, x: 0 });
          const animations = [controls1, controls2, controls3, controls4, controls5];
          var c = 0;
          for (let anim of animations) {
             anim.start({ opacity: 1, x: 0, transition: { duration:0.6, delay: c+=0.4 } });
          }
          }
          else{
          controls.start('visible');
          controls1.start({
            opacity:1,
            y:0,
            transition: { duration: 0.7, delay:0 },
          });
          controls2.start({
            opacity:1,
            y:0,
            transition: { duration: 0.7, delay:0.8 },
          });
          controls3.start({
            opacity:1,
            y:0,
            transition: { duration: 0.7, delay:1.1 },
          });
          controls4.start({
            opacity:1,
            y:0,
            transition: { duration: 0.7, delay:1.4 },
          });
          controls5.start({
            opacity:1,
            y:0,
            transition: { duration: 0.7, delay:1.7 },
          });
        }
        }
      }, [controls, inView, inView1]);



      const variants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
      };


  return (
    <motion.div 
    ref={ref}
    initial="hidden"
    animate={controls}
    variants={variants}
    className="mt-16 services-container w-full flex flex-col justify-center items-center">
      <h1>{translation[5]}</h1>
      <div className="services flex justify-center items-center gap-8">
        <motion.div 
        ref={ref}
        initial={isMobile ? {opacity:0, x:-150}: {opacity:0, y:-70}}
        animate={controls1}
        className='service shadow-lg'>
        
          <div className="image-container">
           <img src="./images/governance.png" alt=''/>
          </div>
          <h2>{translation[0]}</h2>
         
          <div className="view-more-container purple-bg">
            <a href={translation[7]}>{translation[6]}</a>
          </div>
        </motion.div>
        <motion.div 
        ref={ref}
        initial={isMobile ? {opacity:0, x:-150}: {opacity:0, y:-70}}
        animate={controls2}
         className='service shadow-lg'>
          <div className="image-container">
           <img src="./images/emotional_health.png" alt=''/>
          </div>
          <h2>{translation[1]}</h2>
          <div className="view-more-container blue-bg">
          <a href={translation[8]}>{translation[6]}</a>
          </div>
        </motion.div>
        <motion.div 
        ref={ref}
        initial={isMobile ? {opacity:0, x:-150}: {opacity:0, y:-70}}
        animate={controls3}
         className='service shadow-lg'>
          <div className="image-container">
           <img src="./images/sustainable.png" alt=''/>
          </div>
          <h2>{translation[2]}</h2>
          <div className="view-more-container green-bg">
          <a href={translation[9]}>{translation[6]}</a>
          </div>
        </motion.div>
        <motion.div 
        ref={ref}
        initial={isMobile ? {opacity:0, x:-150}: {opacity:0, y:-70}}
        animate={controls4} className='service shadow-lg'>
          <div className="image-container">
           <img src="./images/lifestyle.png" alt=''/>
          </div>
          <h2>{translation[3]}</h2>
          <div className="view-more-container pink-bg">
          <a href={translation[10]}>{translation[6]}</a>
          </div>
        </motion.div>  
        <motion.div 
        ref={ref}
        initial={isMobile ? {opacity:0, x:-150}: {opacity:0, y:-70}}
        animate={controls5}
         className='service shadow-lg'>
          <div className="image-container">
           <img src="./images/habit.png" alt=''/>
          </div>
          <h2>{translation[4]}</h2>
          <div className="view-more-container orange-bg">
          <a href={translation[11]}>{translation[6]}</a>
          </div>
        </motion.div> 
      </div>
    </motion.div>
  )
}

export default Home_services




