import React, { useEffect } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
const Brain_title_list = () => {
  const {t} = useTranslation();
  var list = [t("BrainNews.list-title-1"), t("BrainNews.list-title-2"), t("BrainNews.list-title-3"), t("BrainNews.list-title-4")];
const [ref, inView] = useInView({ triggerOnce: true });
const box1 = useAnimation();  
const box2 = useAnimation();  
const box3 = useAnimation();  
const box4 = useAnimation();  

useEffect(() => {
    if (inView) {
      box1.start({
            y:0,
            opacity:1,
            transition:{delay:0.4, duration:0.8, type:"spring"}
        });
        box2.start({
          y:0,
          opacity:1,
          transition:{delay:1, duration:0.8, type:"spring"}
      });
      box3.start({
        y:0,
        opacity:1,
        transition:{delay:1.6, duration:0.8, type:"spring"}
    });
    box4.start({
      y:0,
      opacity:1,
      transition:{delay:2.2, duration:0.8, type:"spring"}
  });  
    }
}, [box1,box2,box3,box4,inView]);

  return (
    <div className='brain-title-list-container flex gap-20 w-full justify-center p-20'>
      <motion.div 
      ref={ref}
      initial={{y:-200, opacity:0}}
      animate={box1}
      className="brain-title-box shadow-md blue-bg">
        <div className='flex justify-center items-center brain-lis-box-icon-container p-2 h-60'>
        <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 566.83 429.38">
          <path d="m494.8,264.31s22.57,65.79-61.25,93.73c0,0-12.89,38.68,13.97,69.84h-60.17s-12.89-62.32-108.53-90.26" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
          <path d="m431.4,264.31s3.22,46.66-31.16,50.85c-34.38,4.19-51.58-23.16-47.28-50.85,4.3-27.69,25.79-55.63,65.55-55.63s46.21,27.94,46.21,27.94" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
          <path d="m432.48,138.83s96.71-40.83,126.8,41.91c30.09,82.74-61.71,126.8-61.71,126.8" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
          <path d="m352.31,283.89s-11.28,54.8-91.81,53.73c-80.54-1.07-109.55-52.65-109.55-89.19s9.67-104.23,70.92-99.93c61.25,4.3,48.78,37.61,48.78,37.61" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
          <path d="m283.52,120.56s-4.38,28.24-36.24,32.39" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
          <path d="m309.98,58.24s-8.6,48.35,33.31,62.32c41.91,13.97,65.22-10.75,65.22-10.75" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
          <path d="m352.31,176.44s22.16-16.93,6.57-47.68" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
          <path d="m315.35,218.14s-2.36,43.77-44.7,46.16c-42.34,2.39-49.86-37.36-49.86-37.36" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
          <path d="m127.31,165.69s4.3,37.61-30.09,42.98c-34.39,5.37-49.43-21.49-49.43-21.49" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
          <path d="m87.64,143.13s-24.44-69.84,33.94-106.38C179.96.22,231.97,24.93,251.63,44.27" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
          <path d="m394.87,58.24s-11.81-43.27-92.41-54.41c-80.59-11.14-81.67,21.24-81.67,21.24" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
          <path d="m522.04,136.76s.2-95.9-149.46-106.89" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
          <path d="m242.29,102.3s-24.71-27.37-52.65-21.74c-27.94,5.63-32.24,40.01-32.24,40.01" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
          <path d="m82.56,91.54S-1.63,104.79,1.59,209.39c3.22,104.59,162.56,82.67,162.56,82.67" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
        </svg>
        </div>
        <p>{list[0]}</p>
      </motion.div >
      <motion.div 
      ref={ref}
      initial={{y:-200, opacity:0}}
      animate={box2}
      className="brain-title-box ecology shadow-md green-bg">
      <div className='flex justify-center items-center brain-lis-box-icon-container p-2 h-60'>
      <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 596.14 720.02">
        <path d="m172.84,521.88c-13.87-25.25-21.75-54.24-21.75-85.08,0-97.76,79.25-177.01,177.01-177.01s177.01,79.25,177.01,177.01-79.25,177.01-177.01,177.01c-45.83,0-87.59-17.41-119.02-45.99" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem  "/>
        <polygon points="100.23 718.52 1.5 718.52 1.5 546.99 100.23 546.99 100.23 560.96 100.23 718.52" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem "/>
        <path d="m339.61,613.42s24.89-8.58,24.89-30.52-41.88-26.93-41.88-26.93c0,0-66.82-18.95-96.73-29.92-29.92-10.97-68.81,1.99-125.65,34.9" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem  "/>
        <path d="m100.23,710.54s35.9-31.91,89.75-18.95c53.85,12.96,120.67,22.72,187.48-3.1,48.21-18.63,147.82-73.2,199.56-102.15,17.68-9.89,23.02-32.8,11.58-49.51h0c-10.67-15.58-31.85-19.64-47.57-9.18-30.77,20.49-82.13,51.51-128.78,64.91" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem  "/>
        <path d="m452.75,311.13s-27.42,8.49-20.44,30.43c6.98,21.94,21.29,35.9,9.65,48.87-11.64,12.96-10.65,22.94-4.66,31.91,5.98,8.98,7.98,27.92-15.96,29.92-23.93,1.99-69.81,25.93-56.84,47.87,12.96,21.94,62.83,41.88,73.8,11.97,10.97-29.92,60.55-28.56,60.55-28.56" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem "/>
        <path d="m232.36,295.69s11.47,22.91,40.39,15.45c28.92-7.47,50.86,7.49,43.88,24.44-6.98,16.95-75.59,21.94-60.73,44.88,14.86,22.94,43.78,11.66,50.76,38.74,6.98,27.08-18.95,20.1-15.96,52.01,2.99,31.91-2,46.21-6.98,51.03-4.99,4.82-10.77-20.11-27.82-46.04-17.05-25.93-38.99-32.91-23.54-52.85,15.46-19.95-57.34-62.78-57.34-62.78" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem "/>
        <line x1="328.1" y1="259.79" x2="328.1" y2="70.31" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem  "/>
        <path d="m333.58,192.97s112.69-74.79-5.48-191.47c0,0-125.15,119.67,0,191.47" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem  "/>
        <line x1="353.53" y1="70.31" x2="327.28" y2="96.17" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem "/>
        <line x1="353.53" y1="121.74" x2="327.28" y2="147.6" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem  "/>
        <line x1="300.18" y1="97.24" x2="327.28" y2="121.74" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem  "/>
      </svg>
        </div>
        <p>{list[1]}</p>
      </motion.div >
      <motion.div 
      ref={ref}
      initial={{y:-200, opacity:0}}
      animate={box3}
      className="brain-title-box shadow-md yellow-bg">
      <div className='flex justify-center items-center brain-lis-box-icon-container p-2 h-60'>
      <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 597.78 533.79">
  <g>
    <path d="m397.98,422.11c0,60.85-49.33,110.18-110.18,110.18s-110.18-49.33-110.18-110.18h220.35Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <path d="m389.31,424.92l-18.99,21.1v16.53s-9.49,26.02-25.32,26.02-8.79-37.18-8.79-37.18l-23.56-6.42-9.85-22.86" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <path d="m246.2,422.11s4.22,21.1,27.78,24.97v30.12s2.46,20.16-10.9,22.62c-13.36,2.46-2.9,26.72-2.9,26.72" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <line x1="287.81" y1="420.14" x2="287.81" y2="263.66" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <path d="m290.15,336.8s43.97-33.32-2.45-79.12c0,0-49.94,41.14-2.47,79.12" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <line x1="287.81" y1="404.31" x2="367.51" y2="329.06" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <path d="m315.12,375.01s-8.09-53.33,56.26-49.46c0,0,1.76,67.16-53.45,53.45" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <line x1="284.88" y1="404.31" x2="205.17" y2="329.06" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <path d="m257.57,375.01s8.09-53.33-56.26-49.46c0,0-1.76,67.16,53.45,53.45" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
  </g>
  <g>
    <polygon points="1.5 153.93 1.5 210.44 50.47 237.75 101.8 209.5 101.8 157.69 51.65 124.73 1.5 153.93" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <polygon points="101.8 153.93 101.8 210.44 150.78 237.75 202.11 209.5 202.11 157.69 151.96 124.73 101.8 153.93" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <path d="m69.55,165.23c0,9.75-7.91,17.66-17.66,17.66s-17.66-7.91-17.66-17.66,7.91-17.66,17.66-17.66,17.66,7.91,17.66,17.66Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <path d="m169.03,165.23c0,9.75-7.91,17.66-17.66,17.66s-17.66-7.91-17.66-17.66,7.91-17.66,17.66-17.66,17.66,7.91,17.66,17.66Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <path d="m20.81,213.5v-.47c0-16.9,13.7-30.61,30.61-30.61h0c16.9,0,30.61,13.7,30.61,30.61v.47" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <path d="m121.35,213.5v-.47c0-16.9,13.7-30.61,30.61-30.61h0c16.9,0,30.61,13.7,30.61,30.61v.47" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <polyline points="52.84 125.71 52.84 60.94 105.92 7.25 150.77 49.16 150.77 123.75" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <line x1="80.93" y1="67.45" x2="105.92" y2="44.69" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <line x1="83.57" y1="97.27" x2="105.92" y2="76.91" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <line x1="105.92" y1="10.08" x2="105.92" y2="131.79" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
  </g>
  <g>
    <polygon points="555.16 1 494.26 30.44 535.37 121.79 596.78 92.86 555.16 1" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <circle cx="528.07" cy="41.66" r="10.97" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <polygon points="328.35 7.25 389.25 36.69 348.14 128.04 286.73 99.11 328.35 7.25" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <circle cx="355.44" cy="47.92" r="10.97" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <path d="m499.31,41.66s-6.7,5.9-19.64,0c-12.94-5.9-26.64-10.97-26.64-10.97,0,0-7.23-2.9-25.88,6.8-18.65,9.7-17.13,8.81-21.32,23.91-4.19,15.1-8.75,29.94,0,35.65,8.75,5.71,19.79-7.04,24.74-29.4l24.36-6.25s28.25,20.1,44.39,37.01c9.32,9.77,16.56,19.23,21.01,25.48,2.99,4.19,2.52,9.92-1.12,13.56h0c-4.3,4.3-11.34,4.07-15.35-.51l-19.24-21.99" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <path d="m467.11,128.04l19.27,23.38c4.26,5.18,12.02,5.65,16.89,1.04h0c4.63-4.39,4.83-11.7.45-16.34l-7.63-8.08" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <path d="m448.83,141.84l18.52,19.99c3.91,4.22,10.45,4.63,14.86.93h0c4.65-3.91,5.17-10.88,1.14-15.43l-4.87-5.5" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <path d="m366.37,138.59h0c-4.4-3.57-5.08-10.03-1.51-14.44l13.9-17.15c3.57-4.4,10.03-5.08,14.44-1.51h0c4.4,3.57,5.08,10.03,1.51,14.44l-13.9,17.15c-3.57,4.4-10.03,5.08-14.44,1.51Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <path d="m380.95,153.17h0c-4.4-3.57-5.08-10.03-1.51-14.44l21.88-27c3.57-4.4,10.03-5.08,14.44-1.51h0c4.4,3.57,5.08,10.03,1.51,14.44l-21.88,27c-3.57,4.4-10.03,5.08-14.44,1.51Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <path d="m401.32,161.99h0c-4.4-3.57-5.08-10.03-1.51-14.44l21.88-27c3.57-4.4,10.03-5.08,14.44-1.51h0c4.4,3.57,5.08,10.03,1.51,14.44l-21.88,27c-3.57,4.4-10.03,5.08-14.44,1.51Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <path d="m422.4,170.36h0c-4.4-3.57-5.08-10.03-1.51-14.44l8.44-10.41c3.57-4.4,10.03-5.08,14.44-1.51h0c4.4,3.57,5.08,10.03,1.51,14.44l-8.44,10.41c-3.57,4.4-10.03,5.08-14.44,1.51Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <path d="m466.66,161.08s5.2,9.05-2.41,16.09c-7.61,7.04-18.08-.67-24.36-8.04" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <path d="m380.48,56.19s13.8,10.89,24.21,9.32" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
    <line x1="352.74" y1="117.82" x2="364.86" y2="124.15" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
  </g>
  <path d="m486.08,187.17c5.33,18.31,8.18,37.66,8.18,57.69,0,64.59-29.71,122.24-76.2,160.03" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
  <path d="m165.51,79.16c34.27-25.4,76.69-40.43,122.63-40.43" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
  <path d="m153.51,400.95c-43.79-37.8-71.5-93.71-71.5-156.09" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
</svg>
        </div>
        <p>{list[2]}</p>
      </motion.div >
      <motion.div 
      ref={ref}
      initial={{y:-200, opacity:0}}
      animate={box4}
      className="brain-title-box shadow-md orange-bg">
      <div className='flex justify-center items-center brain-lis-box-icon-container p-2 h-60'>
      <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 598.28 429.94">
        <circle cx="296.15" cy="83.05" r="81.55" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
        <path d="m436.07,129.42s4.8-74.35,73.56-79.15c68.76-4.8,87.15-31.18,87.15-31.18,0,0,3.2,160.7-164.7,139.92" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
        <path d="m519.22,89.97s-65.56,13.06-100.74,65.03" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
        <path d="m69.09,89.97s-21.59-19.72-50.37-6.92c-28.78,12.79-12.79,58-12.79,58,0,0,15.19,30.74,47.17,23.55,31.98-7.2,35.98-1.02,47.97,1.09,11.99,2.11,42.37-.32,41.57-43.48-.8-43.16-36.78-48.5-73.56-32.24Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
        <path d="m72.08,67.86s26.59,3.2,41.78-27.18c0,0-39.19-15.99-41.78,27.18Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
        <path d="m65.89,66.26s-32.38,4.4-40.38-24.78c0,0,27.98-13.99,43.17,18.39" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
        <line x1="68.69" y1="88.25" x2="72.08" y2="67.86" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
        <rect x="189.02" y="342.89" width="46.37" height="85.55" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
        <rect x="339.33" y="342.89" width="46.37" height="85.55" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
        <rect x="168.23" y="362.08" width="20.79" height="49.57" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
        <rect x="385.7" y="362.08" width="20.79" height="49.57" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
        <rect x="235.39" y="368.48" width="103.94" height="34.38" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
        <path d="m295.15,190.99h0c-55.75,0-100.94,45.19-100.94,100.94v2.2h201.88v-2.2c0-55.75-45.19-100.94-100.94-100.94Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6rem"/>
      </svg>
        </div>
        <p>{list[3]}</p>
      </motion.div>
    </div >
  )
}

export default Brain_title_list
