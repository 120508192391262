import Service_Results from "./Service_Results";
import Service_methods from "./Service_Methods";
import Service_testimonial from "./Service_Testimonial";
import Service_desc from "./Service_desc"
import Service_title from "./Service_title"
import { useTranslation } from 'react-i18next';

export default function Holistic() {

  const {t} = useTranslation()
  var DescTranslations = [t("Holistic.colored-desc"), t("Holistic.service-objective"), t("Holistic.service-objective-desc-1"), t("Holistic.service-objective-desc-2")]
  var resultsTranslations = [t("Holistic.result-1"),t("Holistic.result-2"),t("Holistic.result-3"),
                    t("Holistic.result-4"),t("Holistic.result-5")];


  return (
    <div className="Holistic-service-container">
      
      {/* Service Title section */}
      <Service_title service_type={"Holistic"}/>

      {/* Service desc section */}
      <Service_desc service_type={"Holistic"} translations={DescTranslations}/>

      {/* Results Section */}
      <Service_Results service_type={"Holistic"} translations={resultsTranslations}/>

      {/* Methods Section */}
      <Service_methods/>

      {/* Testimonial Section */}
      <Service_testimonial service_type={"Holistic"}/>

    </div>
  )
}

