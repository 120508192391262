import { Link } from "react-router-dom";
import Nav from "./components/Nav";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import ReactGA from "react-ga";

// const TrackingId = "G-D4MX4GB75P";
// ReactGA.initialize(TrackingId);

const App = () => {
  return (
    <div className="w-full">
      <Navbar />
      <Nav />
      <Footer />
    </div>
  );
};

export default App;
