import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Navigation, Autoplay } from 'swiper/modules';
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import { useTranslation } from 'react-i18next';

const Service_Results = ({service_type, translations}) => {
    const ref = useRef();
    const [ref1, inView] = useInView({ triggerOnce: true });
    const controllogo = useAnimation();  
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

useEffect(() => {
    if (inView) {
        controllogo.start({
            y:0,
            opacity:1,
            scale: 1,
            transition:{delay:0.8, duration:1, type:"spring"}
        });
    }
}, [controllogo,inView]);

    const [swiper, setSwiper] = useState(null);

    const onSwiper = (swiperInstance) => {
        setSwiper(swiperInstance);
    };

    var slides = [];
  var servicenumber;

 
    // eslint-disable-next-line default-case
    switch (service_type) {
      case 'Corporate':
        servicenumber = 1;
        break;
      case 'Emotional':
        servicenumber = 2;
        break;
      case 'Sustainable':
        servicenumber = 3;
        break;
    case 'Holistic':
        servicenumber = 4;
        break;
    case 'Habit':
        servicenumber = 5;
        break;    
    }

    for (let i = 0; i < translations.length; i++) {
      slides.push(
        <SwiperSlide key={i}>
          <div className='services-method-div flex justify-center items-center'>
            <div className='service-method-logo'>
              <img src={`/svgs/service${servicenumber}-res${i + 1}.svg`} alt={`res${i + 1}`} />
            </div>
            <p className='h-28'>{translations[i]}</p>
          </div>
        </SwiperSlide>
      );
    }


    

    return (
        <motion.div 
        ref={ref1}
        initial={{ y:-200, scale:0.6, opacity:0 }}
        animate={controllogo}
        className="results-div m-20 relative">
            <div className="results-title-div">
                <h2>{(currentLanguage === "es") ? "RESULTADOS" : "RESULTS"}</h2>
            </div>
            <Swiper
                spaceBetween={80}
                ref={ref}
                breakpoints={{
                    600: {
                        slidesPerView: '1',
                    },
                    800:{
                        slidesPerView:'3'
                    }
                }
                }
                autoplay={{ delay: 3000 }}
                loop={true}
                className="mySwiper"
                onSwiper={onSwiper} // Callback to get the swiper instance
            >
                {slides}
            </Swiper>
        
        {swiper && (
            <div className=' z-50'>
                <button className="slideNextBtn" onClick={() => swiper.slideNext()}><GrNext size={43} /></button>
                <button className="slidePrevBtn" onClick={() => swiper.slidePrev()}><GrPrevious size={43} /></button>
            </div>
        )}
        </motion.div>

    );
}

export default Service_Results;
