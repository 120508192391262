import React, { useEffect } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
const Mission_Vision = () => {
  const {t} = useTranslation();
  var text = [t("AboutUs.mision-title"), t("AboutUs.vision-title"), t("AboutUs.mision-desc"), t("AboutUs.vision-desc"), t("AboutUs.mision-desc-bold")];
    const [ref, inView] = useInView({ triggerOnce: true });
    const controlvision = useAnimation();
    const controlmission = useAnimation();
    useEffect(() => {
      if (inView) {
        controlvision.start({
          x:0,
          opacity:1,
          transition: {delay:1, duration:1}
        });
        controlmission.start({
          x:0,
          opacity:1,
          transition: {delay:0.4, duration:1}
        });
      }
    },[controlmission,controlvision,inView]);


  return (
    <div className="vision-mision-container overflow-hidden p-20 flex flex-col gap-8 text-white">
        <motion.div 
        ref={ref}
        initial={{x:-250, opacity:0}}
        animate={controlmission}
         className="mision-container blue-bg relative overflow-hidden">
          {/* <h2 className="light-blue-bg shadow-md">{text[0]}</h2> */}
          <div className='h-full w-1/12 light-blue-bg flex justify-center items-center absolute left-0 desktop-h1'>
            <h2 className="">{text[0]}</h2>
          </div>
          <h2 className="mobile-h1 light-blue-bg rounded-md">{text[0]}</h2>
          <p>{text[2]}</p>
    
        </motion.div>
        <motion.div 
        ref={ref}
        initial={{x:-250, opacity:0}}
        animate={controlvision} className="vision-container pink-bg relative overflow-hidden">
          {/* <h2 className="light-pink-bg shadow-md">{text[1]}</h2> */}
          <div className='h-full w-1/12 light-pink-bg flex justify-center items-center absolute left-0 desktop-h1'>
            <h2 className="">{text[1]}</h2>
          </div>
          <h2 className="mobile-h1 light-pink-bg rounded-md">{text[1]}</h2>
          <p>{text[3]}</p>
        </motion.div>
    </div>
  )
}

export default Mission_Vision
