import Service_Results from "./Service_Results";
import Service_methods from "./Service_Methods";
import Service_testimonial from "./Service_Testimonial";
import Service_desc from "./Service_desc"
import Service_title from "./Service_title"
import { useTranslation } from 'react-i18next';

export default function Sustainable() {

  const {t} = useTranslation()
  var DescTranslations = [t("Sustainable.colored-desc"), t("Sustainable.service-objective"), t("Sustainable.service-objective-desc-1"), t("Sustainable.service-objective-desc-2")]
  var resultsTranslations = [t("Sustainable.result-1"),t("Sustainable.result-2"),t("Sustainable.result-3"),
                    t("Sustainable.result-4")]


  return (
    <div className="Sustainable-service-container">
      
      {/* Service Title section */}
      <Service_title service_type={"Sustainable"}/>

      {/* Service desc section */}
      <Service_desc service_type={"Sustainable"} translations={DescTranslations}/>

      {/* Results Section */}
      <Service_Results service_type={"Sustainable"} translations={resultsTranslations}/>

      {/* Methods Section */}
      <Service_methods/>

      {/* Testimonial Section */}
      <Service_testimonial service_type={"Sustainable"}/>

    </div>
  )
}

