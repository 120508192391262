import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { db } from '../../FirebaseConfig';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';

// import required modules
import { EffectCards, Autoplay  ,EffectCreative, Navigation } from 'swiper/modules';

const Brain_inclusions = () => {
  const [blogs, setBlogs] = useState([]);
  const [showBlogContent, setBlogContent] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const {i18n} = useTranslation();

  const getBlogs = async () => {
    try{
      const collectionRef = db.collection("brainblogs");
      const snapshot = await collectionRef.get();
    
      const documents = [];
      snapshot.forEach(doc => {
        documents.push({ id: doc.id, ...doc.data() });
      });
    
      setBlogs(documents)
    } catch (error) {
      console.error('Error fetching Blogs:', error);
    }
  };
  useEffect(() => {
    getBlogs();
},[]);

function toggleBloggContent(blog){
  setSelectedBlog(blog);
  setBlogContent(!showBlogContent);
}


  return (
    <div className='relative'>
      

      <Swiper
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards, Autoplay]}
        className="swipeBlogs mobile"
        autoplay={true}
      >
        {blogs && blogs.map((blog, index) => (
                  <SwiperSlide onClick={() => {toggleBloggContent(blog)}} key={index} className='swipeSlideBlogs'>
                    <div className="blog-image-container">
                      <img src={blog.image_url} alt="" />
                      <div className="overlay pink-overlay">
                        <h2 className='font-semibold text-2xl' >{(i18n.language === "es") ? blog.title_es : blog.title_en}</h2>
                      </div>
                    </div>
        </SwiperSlide>
        ))}
      </Swiper>

      <Swiper
       grabCursor={true}
       effect={'creative'}
       navigation={true}
       loop={true}
       creativeEffect={{
         prev: {
           shadow: true,
           translate: [0, 0, -400],
         },
         next: {
           translate: ['100%', 0, 0],
         },
       }}
       modules={[EffectCreative, Navigation, Autoplay]}
       className="mySwiper desktop custom-navigation"
      >
        {blogs && blogs.map((blog, index) => (
                  <SwiperSlide key={index} className='swipeSlideBlogs h-full bg-white'>
                    <div className="blog-image-container h-full">
                      <img src={blog.image_url} alt="" className='h-full'/>
                      <div className="overlay pink-overlay">
                        <h2 className=' font-semibold text-4xl '>{(i18n.language === "es") ? blog.title_es : blog.title_en}</h2>
                        <h3 className=''>{(i18n.language === "es") ? blog.desc_title_es : blog.desc_title_en}</h3>
                        <p className="clamp-3" style={{ whiteSpace: 'pre-line' }}>
                          {
                            (i18n.language === "es") ?
                              blog.text_es :
                              blog.text_en
                          }
                        </p>
                        <div className='absolute bottom-5 right-5 bg-white color-pink font-extrabold text-lg hover:scale-110 hover:cursor-pointer transition-all px-3 py-1 shadow-xl w-fit rounded-xl'>
                          <a onClick={() => {toggleBloggContent(blog)}}>{(i18n.language === "es") ? "Ver más" : "View more"}</a>
                        </div>
                      </div>
                    </div>
        </SwiperSlide>
        ))}
      </Swiper>

      {/* Modal for displaying the selected blog content */}
      {showBlogContent && selectedBlog && (
        <div className="z-9999 fixed inset-10 overflow-y-auto flex shadow-xl rounded-lg items-center justify-center bg-white">
          <div className="p-4 flex flex-col rounded-md h-full">
            <img src={selectedBlog.image_url} alt="" className='w-full'/>
            <h2 className='font-semibold mt-4 text-4xl '>{(i18n.language === "es") ? selectedBlog.title_es : selectedBlog.title_en}</h2>
            <h3 className='text-2xl my-2'>{(i18n.language === "es") ? selectedBlog.desc_title_es : selectedBlog.desc_title_en}</h3>
            <p style={{ whiteSpace: 'pre-line' }}>
              {(i18n.language === "es") ? selectedBlog.text_es : selectedBlog.text_en}
            </p>
            <button
              className="text-2xl w-fit self-end font-extrabold text-red-600 hover:text-gray-800"
              onClick={() => toggleBloggContent(null)}
            >
              Close
          </button>
          </div>
        </div>
      )}
    </div>

  )
}

export default Brain_inclusions
