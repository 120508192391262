
import { Link, useLocation } from 'react-router-dom';
import { motion, useAnimation } from "framer-motion";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';


const Navbar = () => {
    
    const [showServices, setShowServices] = useState(false);
    const [isSticky, setSticky] = useState(false);
    const servicesRef = useRef(null);
    const { t, i18n} = useTranslation();
    var list = [t("Navbar.about_us"), t("Navbar.services"), t("Navbar.brain_news"), t("Navbar.contact_us"), t("Navbar.service-1"), t("Navbar.service-2"), t("Navbar.service-3"), t("Navbar.service-4"), t("Navbar.service-5")];
    const [isMobileNavbarVisible, setMobileNavbarVisibility] = useState(false);

    const changeLang = (lang) => {
      i18n.changeLanguage(lang)
    }

    const toggleNavbar = () => {
      setMobileNavbarVisibility(!isMobileNavbarVisible);
    };

    const handleServicesClick = () => {
        setShowServices(!showServices);
      };

    const handleClickOutside = (event) => {
        if (servicesRef.current && (servicesRef.current.contains(event.target) || event.target.classList.contains('nav-list-a'))) {
            return;
          }
          setShowServices(false);
      };
    
      useEffect(() => {
        const handleClick = (event) => {
            handleClickOutside(event);
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const heroSectionHeight = 200; // Adjust this value as needed
            setSticky(window.scrollY > heroSectionHeight);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const location = useLocation();
    const pathname = location.pathname;

    const routes = useMemo(() => [
        {
            label: list[0],
            active: pathname === "/aboutUs",
            href: "aboutUs"
        }, 
        {
            label: list[1],
            active: pathname.includes("/services"),
            href:""
        }, 
        {
            label: list[2],
            active: pathname === "/brainNews",
            href: "/brainNews"
        }, 
        {
            label: list[3],
            active: pathname === "/contactUs",
            href: "/contactUs"
        }
    ], [pathname, list]);

    const routesMobile = useMemo(() => [
      {
          label: "HOME",
          active: pathname === "/",
          href: "/"
      },
      {
          label: list[0],
          active: pathname === "/aboutUs",
          href: "aboutUs"
      }, 
      {
          label: list[1],
          active: pathname.includes("/services"),
          href:""
      }, 
      {
          label: list[2],
          active: pathname === "/brainNews",
          href: "/brainNews"
      }, 
      {
          label: list[3],
          active: pathname === "/contactUs",
          href: "/contactUs"
      }
  ], [pathname, list]);

    const servicesVariants = {
        hidden: {
          opacity: 0,
          height: 0,
        },
        visible: {
          opacity: 1,
          height: 'auto',
        },
      };


  return (
    <motion.div 
    initial={{ opacity: 0, y:-15}}
    animate={{ opacity: 1, y:0, transition: { duration: 0.5 }}}
    className={`flex w-full ${isSticky ? 'fixed top-0' : 'relative'} transition-all duration-300 navbar`}>
        <div className='hidden lg:flex gap-2 h-32 w-full p-5 justify-center relative'>
                <div className='flex w-5/6 items-center justify-center'>
                
                    <div className="nav-list flex justify-center">
                        <div className='gap-12 flex items-center bg-white w-fit p-3 pl-10 shadow-xl rounded-md relative'>
                        <div className="logo-container justify-start flex absolute">
                        <div className='w-fit bg-white p-2 rounded-full shadow-xl'>
                        <Link to="/">
                          <img src="/images/logo.png" alt="Logo" />
                        </Link>
                        </div>
                    </div>
                        {routes.map((item) => (
                <React.Fragment key={item.label}>
                  {(item.label === 'SERVICIOS' || item.label === "SERVICES") ? (
                    <div
                      className={`nav-list-a ${item.active ? "active" : ""} relative`}
                      onClick={handleServicesClick}
                      locale={(list[1] === "NOSOTROS") ? "es" : "en"}
                    >
                      {item.label}
                      <motion.div
  className="services-div absolute bg-black"
  variants={servicesVariants}
  initial="hidden"
  ref={servicesRef}
  style={{ pointerEvents: showServices ? 'auto' : 'none' }}
  animate={showServices ? 'visible' : 'hidden'}
>
<ul>
  <li><Link to="/services/corporate">{list[4]}</Link></li>
  <li className="divider"></li>
  <li><Link to="/services/emotional">{list[5]}</Link></li>
  <li className="divider"></li>
  <li><Link to="/services/sustainable">{list[6]}</Link></li>
  <li className="divider"></li>
  <li><Link to="/services/holistic">{list[7]}</Link></li>
  <li className="divider"></li>
  <li><Link to="/services/habit">{list[8]}</Link></li>
</ul>
</motion.div>
                    </div>
                  ) : (
                    <Link className={`nav-list-a ${item.active ? "active" : ""}`}
                      to={item.href}
                      locale={(list[1] === "NOSOTROS") ? "es" : "en"}
                    >
                      {item.label}
                    </Link>
                  )}
                </React.Fragment>
                    ))}
                               
                        </div>
                        
                    </div>
                    <div className="language-div flex justify-end">
                        <Link onClick={() => {changeLang((list[0] === "NOSOTROS") ? "en" : "es")}} className='bg-white p-3 rounded-md shadow-xl hover:cursor-pointer hover:scale-90'>{(list[0] === "NOSOTROS") ? "English" : "Español"}</Link>
                    </div>
                </div>
        </div>

        <div className="mobileNavbar w-11/12 relative flex items-center m-auto justify-center">
          <div className=" mobileLogoNavbar">
            <div className="rounded-full bg-white shadow-xl inside-mobile-container ">
              <button onClick={toggleNavbar}>
                <img src="/images/logo.png" alt="" />
              </button>
            </div>
          </div>

          <div className="language-div flex justify-end">
            <Link onClick={() => changeLang((list[0] === "NOSOTROS") ? "en" : "es")} className='bg-white p-3 rounded-md shadow-xl hover:cursor-pointer hover:scale-90'>{(list[0] === "NOSOTROS") ? "English" : "Español"}</Link>
          </div>


          {isMobileNavbarVisible && 
          <div className="nav-list absolute top-28 w-8/12 left-8 dark-blue-bg rounded-md">
          <div className='gap-5 flex flex-col justify-center items-center w-18/12 p-3 shadow-xl rounded-md'>
          {routesMobile.map((item) => (
  <React.Fragment key={item.label}>
    {(item.label === 'SERVICIOS' || item.label === "SERVICES") ? (
      <div
        className={`nav-list-a ${item.active ? "active" : ""} relative w-full flex justify-center`}
        onClick={handleServicesClick}
        locale={(list[1] === "NOSOTROS") ? "es" : "en"}
      >
        {item.label}
        <motion.div
className="services-div absolute bg-black"
variants={servicesVariants}
initial="hidden"
ref={servicesRef}
style={{ pointerEvents: showServices ? 'auto' : 'none' }}
animate={showServices ? 'visible' : 'hidden'}
>
<ul className='w-full'>
<li><Link to="/services/corporate">{list[4]}</Link></li>
<li className="divider"></li>
<li><Link to="/services/emotional">{list[5]}</Link></li>
<li className="divider"></li>
<li><Link to="/services/sustainable">{list[6]}</Link></li>
<li className="divider"></li>
<li><Link to="/services/holistic">{list[7]}</Link></li>
<li className="divider"></li>
<li><Link to="/services/habit">{list[8]}</Link></li>
</ul>
</motion.div>
      </div>
    ) : (
      <Link
        className={`nav-list-a ${item.active ? "active" : ""} w-full text-center`}
        to={item.href}
        onClick={toggleNavbar}
        locale={(list[1] === "NOSOTROS") ? "es" : "en"}
      >
        {item.label}
      </Link>
    )}
  </React.Fragment>
      ))}
                 
          </div>
          
      </div>
          }
        </div>
    </motion.div>
  )
}

export default Navbar
