import React, { useEffect } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';

const Corporate_rethical = ({t}) => {
const [ref1, inView1] = useInView({ triggerOnce: true });
const [ref, inView] = useInView({ triggerOnce: true });
const controlbgImage = useAnimation();  
const controlHeading = useAnimation();  
const bullet1 = useAnimation();  
const bullet2 = useAnimation(); 
const bullet3 = useAnimation(); 
const bullet4 = useAnimation(); 
const bullet5 = useAnimation(); 

useEffect(() => {
  if(inView1){
    controlbgImage.start({
      x:0,
      opacity:1,
      transition:{delaty:0.5, duration:1, type:"spring"}
  });
  }
    if(inView) {
        controlHeading.start({
            y:0,
            opacity:1,
            transition:{duration:1, type:"spring"}
        });
        bullet1.start({
            y:0,
            opacity:1,
            transition:{delay:1, duration:1, type:"spring"}
        });
        bullet2.start({
            y:0,
            opacity:1,
            transition:{delay:1.5, duration:1, type:"spring"}
        });
        bullet3.start({
            y:0,
            opacity:1,
            transition:{delay:2, duration:1, type:"spring"}
        });
        bullet4.start({
            y:0,
            opacity:1,
            transition:{delay:2.5, duration:1, type:"spring"}
        });
        bullet5.start({
            y:0,
            opacity:1,
            transition:{delay:3, duration:1, type:"spring"}
        });
    }
}, [controlbgImage, controlHeading, inView , inView1 , bullet1, bullet2, bullet3, bullet4, bullet5]);

  return (
    <div className="rethical-container flex mt-20 mb-20">
    <motion.div 
    ref={ref1}
    initial={{ opacity:0, x:-150 }}
    animate={controlbgImage}
    className="rethical-image-div ml-5">
      <img src="/images/aboutsUs-title.png"/>
    </motion.div>
    <div className="rethical-dec-div">
      <motion.p 
      ref={ref}
      initial={{ opacity:0, y:-100 }}
      animate={controlHeading}
      className="rethical-title">
      {t[0]}
      </motion.p>
      <ul>
        <motion.li 
        ref={ref}
        animate={bullet1}
        initial={{ y:-50, opacity:0 }}
        className="rethical-li"><div className="rethical-li-bullet"></div><p>{t[1]}</p></motion.li>
         <motion.li 
        ref={ref}
        animate={bullet2}
        initial={{ y:-50, opacity:0 }}
        className="rethical-li"><div className="rethical-li-bullet"></div><p>{t[2]}</p></motion.li>
         <motion.li 
        ref={ref}
        animate={bullet3}
        initial={{ y:-50, opacity:0 }}
        className="rethical-li"><div className="rethical-li-bullet"></div><p>{t[3]}</p></motion.li>
         <motion.li 
        ref={ref}
        animate={bullet4}
        initial={{ y:-50, opacity:0 }}
        className="rethical-li"><div className="rethical-li-bullet"></div><p>{t[4]}</p></motion.li>
         <motion.li 
        ref={ref}
        animate={bullet5}
        initial={{ y:-50, opacity:0 }}
        className="rethical-li"><div className="rethical-li-bullet"></div><p>{t[5]}</p></motion.li>
      </ul>
    </div>
  </div>
  )
}

export default Corporate_rethical
