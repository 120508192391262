import React, { useEffect, useState } from 'react'
import { db } from '../../FirebaseConfig';

function Emailmessages() {

  const [emailMessages, setEmailMessages] = useState([]);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [showDeleteButton, setshowDeleteButton] = useState(false);

  const handleSelectChange = (id) => {
    setSelectedMessages(prevSelectedIds => {
      if (prevSelectedIds.includes(id)) {
        // If the ID is already selected, remove it from the array
        return prevSelectedIds.filter(selectedId => selectedId !== id);
      } else {
        // If the ID is not in the array, add it
        return [...prevSelectedIds, id];
      }
    });
  };

  useEffect(() => {

    if(selectedMessages.length > 0){
      setshowDeleteButton(true);
    }
    else{
      setshowDeleteButton(false);
    }
  }, [selectedMessages]);
  

  const formatDate = (timestamp) => {
    // Convert Firestore Timestamp to JavaScript Date object
    const date = timestamp.toDate();
  
    // Format date as you prefer, e.g., 'MM/DD/YYYY, HH:mm:ss'
    return date.toLocaleString(); // This is a simple example
  };

  const getEmails = async () => {
    try {
      const collectionRef = db.collection("emailMessages").orderBy("timestamp", "desc");
      const snapshot = await collectionRef.get();
     
      const documents = [];
      snapshot.forEach(doc => {
        documents.push({ id: doc.id, ...doc.data() });
      });
     
      setEmailMessages(documents);
    } catch (error) {
      console.error('Error fetching Email Messages:', error);
    }
  };
   
     useEffect(() => {
      getEmails();
     },[]);

     const deleteSelectedMessages = async () => {
      // Ensure there are selected messages to delete
      if (selectedMessages.length > 0) {
        const deletePromises = selectedMessages.map(id => {
          return db.collection("emailMessages").doc(id).delete();
        });
    
        try {
          // Wait for all delete operations to complete
          await Promise.all(deletePromises);
    
          // After deletion, clear the selected messages and refresh the email list
          setSelectedMessages([]);
          getEmails(); // Assuming this function fetches the latest list of messages
    
          // Optionally, update the UI to hide the delete button
          setshowDeleteButton(false);
        } catch (error) {
          console.error("Error deleting selected email messages:", error);
        }
      }
    };
       

  return (
    <div className="container mx-auto p-4 relative">
      <h1 className="text-2xl font-bold mb-4">Email Messages</h1>
      {
        showDeleteButton && (<div className="absolute top-4 right-4">
        <button onClick={deleteSelectedMessages} className=" bg-red-600 text-white py-2 px-4 rounded">
          Delete Selected Videos
        </button>
      </div>)
      }
      {/* Display Partners */}
      {emailMessages && (
        <div className="flex flex-col gap-5">
          {emailMessages.map((message) => (
            <div key={message.id} className="bg-white p-4 rounded shadow relative">
              <input
                type="checkbox"
                onChange={() => handleSelectChange(message.id)}
                className=""
              />
              <div>
                <p>Name: <span className=' font-bold'>{message.firstName} {message.lastName}</span></p>
                <p>Email: <span className=' font-bold'>{message.email}</span></p>
                <p className='mt-5'>Message: {message.message}</p>
                <p className='absolute top-5 right-5'>Sent At: {formatDate(message.timestamp)}</p>
              </div>
            </div>
            ))}
        </div>
      )}
    </div>
  )
}

export default Emailmessages
