import AboutUsInfo from "./AboutUsInfo";
import Locations from "./Locations";
import Mission_Vision from "./Mission_Vision";
import Service_title from "../Services/Service_title";


export default function Aboutus() {

  return (
    <div className="aboutUs-container overflow-hidden">

      {/* Title Section */}
      <Service_title service_type={"AboutUs"}/>

      {/* Vision Mision section */}
      <Mission_Vision/>

      {/* About us Info section */}
      <AboutUsInfo/>

      {/* Location Div */}
      <Locations/>
    </div>
  )
}
