// NotFound.js
import React from 'react';

const NotFound = () => {
  return (
    <div className='w-full h-screen flex flex-col gap-10 font-bold justify-center items-center'>
      <h1 className='color-pink text-6xl'>404 - Page Not Found</h1>
      <p className='text-2xl'>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;