import React from 'react'
import LoginPage from './LoginPage'

const Panel = () => {
  return (
  <div className='panel-container'>
      <LoginPage />
  </div>
  )
}

export default Panel