import { Routes, Route } from "react-router-dom";
import Home from "./HomePage/Home";
import NotFound from "./HomePage/NotFound";
import Corporate from "./Services/Corporate";
import Emotional from "./Services/Emotional";
import Habit from "./Services/Habit";
import Holistic from "./Services/Holistic";
import Sustainable from "./Services/Sustainable";
import ContactUs from "./ContactUs/ContactUs";
import BrainNews from "./BrainNews/BrainNews";
import Aboutus from "./AboutsUs/AboutUs";
import Panel from "./Panel/Panel";
const Nav = () => {
  return (
    <div>
<Routes>
  <Route path="/" element={<Home />} />
  <Route path="/panel" element={<Panel />} />
  <Route path="/services/Corporate" element={<Corporate />} />
  <Route path="/services/Emotional" element={<Emotional />} />
  <Route path="/services/Habit" element={<Habit />} />
  <Route path="/services/Sustainable" element={<Sustainable />} />
  <Route path="/services/holistic" element={<Holistic />} />
  <Route path="/contactUs" element={<ContactUs />} />
  <Route path="/brainnews" element={<BrainNews />} />
  <Route path="/aboutUs" element={<Aboutus />} />
  <Route path="*" element={<NotFound />} /> {/* Add this line */}
</Routes>

    </div>
  );
};
export default Nav;