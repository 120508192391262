import React, { useState, useEffect } from 'react';
import { db } from '../../FirebaseConfig';

const BrainNewsVideosPanel = () => {

//   const [partners, loading, error] = useCollectionData(partnersRef, { idField: 'id' });
const [videos, setVideos] = useState([]);

const getVideos = async () => {
  try{
    const collectionRef = db.collection("brainVideos");
    const snapshot = await collectionRef.get();
  
    const documents = [];
    snapshot.forEach(doc => {
      documents.push({ id: doc.id, ...doc.data() });
    });
  
    setVideos(documents)
  } catch (error) {
    console.error('Error fetching Brain Videos:', error);
  }
  };

  useEffect(() => {
    getVideos();
  },[]);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [newVideoTitle, setNewVideoTitle] = useState('');
  const [newVideoLink, setNewVideoLink] = useState('');
  const [selectedVideo, setSelectedVideo] = useState(null);

  const openAddModal = () => setShowAddModal(true);
  const closeAddModal = () => setShowAddModal(false);

  const openUpdateModal = (video) => {
    setSelectedVideo(video);
    setShowUpdateModal(true);
  };

  const closeUpdateModal = () => {
    setSelectedVideo(null);
    setShowUpdateModal(false);
  };

  const handleUpdateVideo = async () => {
    if (!selectedVideo) { return; }
      // Step 3: Update partner data in Firestore
      try{
      const videoData = {
        title: selectedVideo.title,
        videoLink: selectedVideo.videoLink,
      };
    
      await db.collection('brainVideos').doc(selectedVideo.id).update(videoData);
      console.log(`Brain Videos with ID ${selectedVideo.id} has been updated.`);
    
    
    } catch (error) {
      console.error('Error updating Brain Videos:', error);
    }
    
    getVideos();
    setSelectedVideo(null);
    closeUpdateModal();
  };

  const handleAddVideo = async () => {
    try {
      const videoData = {
        title: newVideoTitle,
        videoLink: newVideoLink,
      };
  
      await db.collection('brainVideos').add(videoData);
  
      console.log('Brain Videos added successfully:', videoData);
    } catch (error) {
      console.error('Error adding Brain Videos:', error);
    }

    getVideos();
    setNewVideoTitle("");
    setNewVideoLink("");
    closeAddModal();
  };

  const handleDeleteVideo = async (video) => {
    try {
      // Step 1: Delete the partner from Firestore
      await db.collection('brainVideos').doc(video.id).delete();
      console.log(`Brain Videos with ID ${video.id} deleted from Firestore.`);

    } catch (error) {
      console.error('Error deleting Brain Videos:', error);
    }

    getVideos();
  };

  return (
    <div className="container mx-auto p-4 relative">
      <h1 className="text-2xl font-bold mb-4">BrainNews Videos</h1>
      {/* Button to open Add Testimonial Modal */}
      <div className="absolute top-4 right-4">
        <button onClick={openAddModal} className="bg-green-500 text-white py-2 px-4 rounded">
          Add Video
        </button>
      </div>
      {/* Display Testimonials */}
      {videos && (
        <div className="flex flex-col gap-5">
          {videos.map((video) => (
            <div key={video.id} className="bg-white p-4 rounded shadow ">
                <iframe className="shadow-md rounded-xl video" src={video.videoLink} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                <p className="text-lg mt-3 font-semibold">{video.title}</p>
                <div className="flex justify-end mt-2">
                 <button onClick={() => openUpdateModal(video)} className="mr-2 text-blue-500">
                    Update
                </button> 
                <button onClick={() => handleDeleteVideo(video)} className="text-red-500">
                    Delete
                </button>
                </div> 
            </div>
            ))}
        </div>
      )}

      {/* Add Testimonial Modal */}
      {showAddModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow w-3/4">
            <h2 className="text-2xl font-bold mb-4">Add Video</h2>
            <label className="block mb-2">Title:</label>
            <input
              type="text"
              value={newVideoTitle}
              onChange={(e) => setNewVideoTitle(e.target.value)}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Video Link:</label>
            <input
              type="text"
              value={newVideoLink}
              onChange={(e) => setNewVideoLink(e.target.value)}
              className="w-full p-2 border rounded mb-4"
            />
            <button onClick={handleAddVideo} className="mx-5 bg-blue-500 text-white py-2 px-4 rounded">
              Add Video
            </button>
            <button onClick={closeAddModal} className="mt-4 bg-gray-500 text-white py-2 px-4 rounded">
              Close
            </button>
          </div>
        </div>
      )}

      {/* Update Partner Modal */}
      {showUpdateModal && selectedVideo && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow w-2/3">
          <h2 className="text-2xl font-bold mb-4">Update Video</h2>
            <label className="block mb-2">Title:</label>
            <input
              type="text"
              value={selectedVideo.title}
              onChange={(e) => setSelectedVideo({ ...selectedVideo, title: e.target.value })}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Video Link:</label>
            <input
              type="text"
              value={selectedVideo.videoLink}
              onChange={(e) => setSelectedVideo({ ...selectedVideo, videoLink: e.target.value })}
              className="w-full p-2 border rounded mb-4"
            />
            <button onClick={() => handleUpdateVideo()} className="mx-2 bg-blue-500 text-white py-2 px-4 rounded">
              Update Video
            </button>
            <button onClick={closeUpdateModal} className="mt-4 bg-gray-500 text-white py-2 px-4 rounded">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BrainNewsVideosPanel;
