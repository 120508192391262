import React, { useEffect } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
const Contact_Title = () => {

const [ref, inView] = useInView({ triggerOnce: true });
const controllogo = useAnimation();  
const controltitle = useAnimation(); 
const {t} = useTranslation();
var text = t("ContactUs.title")
useEffect(() => {
    if (inView) {
        controllogo.start({
            y:0,
            opacity:1,
            transition:{delay:0.4, duration:0.8, type:"spring"}
        });
        controltitle.start({
          y:0,
          opacity:1,
          transition:{delay:0.8, duration:0.8, type:"spring"}
      });
    }
}, [controllogo, controltitle, inView]);

  return (
    <div className="title-container pt-10 pr-20 pl-52 relative">
        <motion.img 
    ref={ref}
    initial={{ y:-100, opacity:0 }}
    animate={controllogo} src="../images/contactUs_title.png"/>
      
      <motion.div 
      ref={ref}
      initial={{ y:-100, opacity:0 }}
    animate={controltitle}
      className="title-div w-fit shadow-md absolute bottom-20">
          <div className="circle-1"></div>
          <div className="circle-2"></div>
          <h1>{text}</h1>
      </motion.div>
      </div>
  )
}

export default Contact_Title
