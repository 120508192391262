import React, { useState, useEffect } from 'react';
import { db, storage } from '../../FirebaseConfig';
const SerViceTestimonials = () => {
const [tests, setTests] = useState([]);
const [showUpdateModal, setShowUpdateModal] = useState(false);
const [selectedTestimonial, setSelectedTestimonial] = useState(null);

const getServiceTestimonials = async () => {
  try{
    const collectionRef = db.collection("serviceTestimonials");
    const snapshot = await collectionRef.get();
  
    const documents = [];
    snapshot.forEach(doc => {
      documents.push({ id: doc.id, ...doc.data() });
    });
  
    setTests(documents)
  } catch (error) {
    console.error('Error fetching Service Testimonials:', error);
  }
};

  useEffect(() => {
    getServiceTestimonials();
  },[]);

  const openUpdateModal = (testimonial) => {
    setSelectedTestimonial(testimonial);
    setShowUpdateModal(true);
  };
  
  const closeUpdateModal = () => {
    setSelectedTestimonial(null);
    setShowUpdateModal(false);
  };

const handleUpdate = async () => {

  if (!selectedTestimonial) { return; }
    try {
      // Step 1: Retrieve the current image URL
      let currentImageUrl = selectedTestimonial.image_url;
      let currentLogoUrl = selectedTestimonial.logo_url;
    
      // Step 2: Check if a new image is provided and upload it
      let imageUrl = currentImageUrl;
      let logoUrl = currentLogoUrl;
      var logoName = selectedTestimonial.logo_name;
      var imageName = selectedTestimonial.image_name;

      if (selectedTestimonial.image_name_file) {
        const storageRef = storage.ref();
        const imageRef = storageRef.child(`serviceTest_images/${selectedTestimonial.id}_image_${selectedTestimonial.image_name_file.name}`);
        const uploadTask = await imageRef.put(selectedTestimonial.image_name_file);
        imageName = `${selectedTestimonial.id}_image_${selectedTestimonial.image_name_file.name}`
        // Get the download URL of the new image
        imageUrl = await uploadTask.ref.getDownloadURL();
      }

      if (selectedTestimonial.logo_name_file) {
        const storageRef1 = storage.ref();
        const logoRef = storageRef1.child(`serviceTest_images/${selectedTestimonial.id}_logo_${selectedTestimonial.logo_name_file.name}`);
        const uploadTask1 = await logoRef.put(selectedTestimonial.logo_name_file);
        logoName = `${selectedTestimonial.id}_logo_${selectedTestimonial.logo_name_file.name}`;
    
        // Get the download URL of the new image
        logoUrl = await uploadTask1.ref.getDownloadURL();
      }
    
      // Step 3: Update partner data in Firestore
      const testData = {
        desc_en: selectedTestimonial.desc_en,
        desc_es: selectedTestimonial.desc_es,
        image_name: imageName,
        logo_url: logoUrl,
        logo_name: logoName,
        image_url: imageUrl
      };
    
      await db.collection('serviceTestimonials').doc(selectedTestimonial.id).update(testData);
      console.log(`Service Testimonial with ID ${selectedTestimonial.id} has been updated.`);
    
      // Step 4: Delete the old image if a new image was uploaded
      if (imageUrl !== currentImageUrl) {
        const oldImageRef = storage.refFromURL(currentImageUrl);
        await oldImageRef.delete();
        console.log('Old image has been deleted.');
      }

      if (logoUrl !== currentLogoUrl) {
        const oldImageRef1 = storage.refFromURL(currentLogoUrl);
        await oldImageRef1.delete();
        console.log('Old Logo has been deleted.');
      }
    
    } catch (error) {
      console.error('Error updating Service Test:', error);
    }
    
    getServiceTestimonials();
    setSelectedTestimonial(null);
    closeUpdateModal();
}

const handleFileChange = (e, fieldName) => {
  const file = e.target.files[0];
  setSelectedTestimonial((prev) => ({
    ...prev,
    [fieldName]: file ? file.name : null,
    [`${fieldName}_file`]: file, // Store the File object
  }));
};


  return (
    <div className="container mx-auto p-4 relative">
      <h1 className="text-2xl font-bold mb-4">Service Testimonial List</h1>
      {tests && (
        <div className="flex flex-col gap-5">
          {tests.map((testimonial) => (
            <div key={testimonial.id} className="bg-white p-4 rounded shadow relative">
                <p className='font-bold text-2xl'>{testimonial.service_title}</p>
                <div className='flex justify-evenly'>
                <div className='w-1/3'>
                    <p className='font-bold'>Logo</p>
                    <img src={testimonial.logo_url} alt="" />
                </div><div className='w-1/3'>
                    <p className='font-bold'>Image</p>
                    <img src={testimonial.image_url} alt="" />
                </div>
                </div>
                <div className="flex">
                <div className='w-1/2'>
                    <p className='font-bold'>English Text</p>
                    <p>{testimonial.desc_en}</p>
                </div>
                <div className='w-1/2'>
                    <p className='font-bold'>Spanish Text</p>
                    <p>{testimonial.desc_es}</p>
                </div>
                </div>
                <button onClick={() => openUpdateModal(testimonial)} className="mr-2 font-bold text-blue-500 absolute top-3 right-3">
                    Update
                </button>
            </div>
            ))}
        </div>
      )}


      {/* Update Partner Modal */}
      {showUpdateModal && selectedTestimonial && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow w-2/3">
            <h2 className="text-2xl font-bold mb-4">Update {selectedTestimonial.id} Testimonial</h2>
            <label className="block mb-2">Text (English)</label>
            <input
              type="text"
              value={selectedTestimonial.desc_en}
              onChange={(e) => setSelectedTestimonial({ ...selectedTestimonial, desc_en: e.target.value })}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Text (Spanish)</label>
            <input
              type="text"
              value={selectedTestimonial.desc_es}
              onChange={(e) => setSelectedTestimonial({ ...selectedTestimonial, desc_es: e.target.value })}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Logo: {selectedTestimonial.logo_name}</label>
              <input
              type="file"
              onChange={(e) => handleFileChange(e, 'logo_name')}
              className="w-full p-2 border rounded mb-4"
              />
              <label className="block mb-2">Image: {selectedTestimonial.image_name}</label>
              <input
              type="file"
              onChange={(e) => handleFileChange(e, 'image_name')}
              className="w-full p-2 border rounded mb-4"
              />
            {/* Add any additional fields you want to update */}

            {/* Button to trigger the update */}
            <button onClick={() => handleUpdate()} className="mx-2 bg-blue-500 text-white py-2 px-4 rounded">
              Update Testimonial
            </button>
            <button onClick={closeUpdateModal} className="mt-4 bg-gray-500 text-white py-2 px-4 rounded">
              Close
            </button>
          </div>
        </div>
      )}      
      

    </div>
  );
};

export default SerViceTestimonials;
