import React, { useRef } from 'react'
import {motion, useScroll, useTransform} from "framer-motion"
import Hero_style from './Hero_style'
import Hero_text from './Hero_text'



const Hero_main = () => {

    const ref = useRef()

    const {scrollYProgress} = useScroll({
        target: ref,
        offset: ["start start", "end start"]
    })

    const yPurple = useTransform(scrollYProgress, [0, 1], ["0%", "2500%"])
    const yPRotate = useTransform(scrollYProgress, [0, 1], [0, 1860])
    const yBRotate = useTransform(scrollYProgress, [0, 1], [1860, 0])
    const yBlue = useTransform(scrollYProgress, [0, 1], ["2500%", "0%"])


  return (
    <div className="hero-container relative w-full">
    <motion.svg initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }} 
        style={{x: yPurple, rotate: yPRotate}} x="0px" y="0px" viewBox="0 0 800 800" className='hero-gear purple-gear'>
<path fill='#652682' d="M412.47,743.83c-7.98,0-15.95,0-23.93,0c-0.73-1.03-1.86-0.66-2.83-0.78c-23.7-2.86-45.12-11.19-63.05-27.24
		c-23.02-20.61-33.7-46.77-33.67-77.5c0.01-4.9,0.19-9.79-1.18-14.57c-2.42-8.49-7.48-12.07-16.24-11.39
		c-5.37,0.42-10.25,2.42-14.88,4.98c-22.68,12.57-46.81,16.65-72.28,12.15c-63.39-11.18-101.21-71.75-91.13-130.47
		c5.91-34.4,23.99-60.81,53.74-79.14c5.3-3.26,10.09-7.15,13.49-12.48c4.18-6.56,3.56-13-1.7-18.7c-2.91-3.16-6.46-5.48-10.19-7.54
		c-29.96-16.55-48.19-41.73-55.21-75.18c-0.67-3.21-0.46-6.61-2.05-9.61c0-7.73,0-15.46,0-23.19c0.84-0.45,0.69-1.29,0.77-2.03
		c2.07-17.12,7.77-32.93,16.83-47.58c32.75-52.93,101.12-66.63,149.51-36.98c2.28,1.4,4.76,2.57,7.28,3.48
		c9.62,3.47,16.58,0.22,20.28-9.38c0.6-1.55,1.04-3.17,1.44-4.79c2.12-8.54,1.15-17.42,2.89-26.03
		c9.37-46.17,36.3-76.41,81.46-90.04c5.69-1.72,11.64-2.57,17.47-3.82c7.46,0,14.92,0,22.38,0c5.37,1.03,10.77,1.9,16.09,3.11
		c47.81,10.92,82.09,49.62,83.74,98.38c0.22,6.52-0.24,13.14,1.84,19.53c2.89,8.88,8.98,12.44,18.11,10.68
		c6.59-1.27,11.99-5.12,17.94-7.8c38.93-17.53,76.76-14.88,111.54,9.63c35.45,24.97,51.04,60.65,47.13,103.73
		c-3.55,39.18-23.19,68.83-57.61,88.32c-3.03,1.71-5.92,3.63-8.43,6.07c-6.52,6.32-7.09,13.45-1.6,20.69
		c3.81,5.02,8.9,8.48,14.2,11.72c44.37,27.11,64.67,80.47,48.62,130.02c-21.93,67.69-96.49,102.57-159.82,64.13
		c-3.08-1.87-6.38-3.31-9.88-4.27c-9.57-2.64-16.15,0.69-19.53,9.93c-1.84,5.03-2.22,10.27-2.19,15.58
		c0.04,8.39-0.56,16.75-2.55,24.91c-10.61,43.39-38.57,69.32-81.16,80.34C422.98,742.02,417.69,742.8,412.47,743.83z M397.63,698.69
		c11.76-0.17,20.23-1.47,28.44-4.34c27.79-9.7,43.5-33.47,41.54-62.81c-0.39-5.94,0.21-11.83,1.2-17.69
		c4.35-25.67,22.62-44.52,48.05-48.92c17.07-2.95,32.92,1.4,47.81,9.69c12.17,6.77,25.17,9.74,38.97,8.25
		c29.28-3.16,52.93-25.44,58.68-54.9c5.47-28.03-8.33-57.22-33.68-71.21c-4.4-2.43-8.64-5.08-12.59-8.21
		c-21.56-17.09-29.62-43.6-20.57-68.34c6.21-16.96,18.15-29,33.4-38.11c38.1-22.75,46.34-72.16,17.63-105.11
		c-22.71-26.07-60.9-28.32-83.32-13.14c-16.28,11.02-34.72,14.34-53.78,7.78c-22.52-7.75-34.4-24.94-39.82-47.36
		c-2.13-8.82-1.65-17.92-2.99-26.82c-4.96-32.92-35.88-59.45-73.48-55.98c-32.86,3.04-59.83,32.8-59.55,66.82
		c0.05,5.94-0.39,11.83-1.39,17.68c-5.2,30.3-29.66,50.81-59.8,49.97c-13.42-0.38-25.52-5.13-37.19-11.5
		c-45.06-24.55-96.95,8.86-98.18,57.58c-0.67,26.58,11.47,46.7,33.98,60.52c8.18,5.02,15.73,10.69,21.96,18.04
		c19.63,23.16,20.27,54.98,1.54,78.47c-6.35,7.96-14.29,13.95-23.07,19.04c-26.8,15.54-40.06,46.67-32.57,76.02
		c11.35,44.47,60.27,64.85,100.02,41.87c20.51-11.86,41.97-14.23,63.23-2.62c22.65,12.37,31.92,32.88,31.83,58.08
		c-0.02,6.08,0.32,12.1,1.71,18.01C342.66,679.22,368.54,698.77,397.63,698.69z"/>
	</motion.svg>
<motion.svg initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }} 
        style={{x: (yBlue), rotate: yBRotate}} x="0px" y="0px" viewBox="0 0 800 800" className='hero-gear blue-gear'>
<path fill='#22b1dd' d="M412.47,743.83c-7.98,0-15.95,0-23.93,0c-0.73-1.03-1.86-0.66-2.83-0.78c-23.7-2.86-45.12-11.19-63.05-27.24
		c-23.02-20.61-33.7-46.77-33.67-77.5c0.01-4.9,0.19-9.79-1.18-14.57c-2.42-8.49-7.48-12.07-16.24-11.39
		c-5.37,0.42-10.25,2.42-14.88,4.98c-22.68,12.57-46.81,16.65-72.28,12.15c-63.39-11.18-101.21-71.75-91.13-130.47
		c5.91-34.4,23.99-60.81,53.74-79.14c5.3-3.26,10.09-7.15,13.49-12.48c4.18-6.56,3.56-13-1.7-18.7c-2.91-3.16-6.46-5.48-10.19-7.54
		c-29.96-16.55-48.19-41.73-55.21-75.18c-0.67-3.21-0.46-6.61-2.05-9.61c0-7.73,0-15.46,0-23.19c0.84-0.45,0.69-1.29,0.77-2.03
		c2.07-17.12,7.77-32.93,16.83-47.58c32.75-52.93,101.12-66.63,149.51-36.98c2.28,1.4,4.76,2.57,7.28,3.48
		c9.62,3.47,16.58,0.22,20.28-9.38c0.6-1.55,1.04-3.17,1.44-4.79c2.12-8.54,1.15-17.42,2.89-26.03
		c9.37-46.17,36.3-76.41,81.46-90.04c5.69-1.72,11.64-2.57,17.47-3.82c7.46,0,14.92,0,22.38,0c5.37,1.03,10.77,1.9,16.09,3.11
		c47.81,10.92,82.09,49.62,83.74,98.38c0.22,6.52-0.24,13.14,1.84,19.53c2.89,8.88,8.98,12.44,18.11,10.68
		c6.59-1.27,11.99-5.12,17.94-7.8c38.93-17.53,76.76-14.88,111.54,9.63c35.45,24.97,51.04,60.65,47.13,103.73
		c-3.55,39.18-23.19,68.83-57.61,88.32c-3.03,1.71-5.92,3.63-8.43,6.07c-6.52,6.32-7.09,13.45-1.6,20.69
		c3.81,5.02,8.9,8.48,14.2,11.72c44.37,27.11,64.67,80.47,48.62,130.02c-21.93,67.69-96.49,102.57-159.82,64.13
		c-3.08-1.87-6.38-3.31-9.88-4.27c-9.57-2.64-16.15,0.69-19.53,9.93c-1.84,5.03-2.22,10.27-2.19,15.58
		c0.04,8.39-0.56,16.75-2.55,24.91c-10.61,43.39-38.57,69.32-81.16,80.34C422.98,742.02,417.69,742.8,412.47,743.83z M397.63,698.69
		c11.76-0.17,20.23-1.47,28.44-4.34c27.79-9.7,43.5-33.47,41.54-62.81c-0.39-5.94,0.21-11.83,1.2-17.69
		c4.35-25.67,22.62-44.52,48.05-48.92c17.07-2.95,32.92,1.4,47.81,9.69c12.17,6.77,25.17,9.74,38.97,8.25
		c29.28-3.16,52.93-25.44,58.68-54.9c5.47-28.03-8.33-57.22-33.68-71.21c-4.4-2.43-8.64-5.08-12.59-8.21
		c-21.56-17.09-29.62-43.6-20.57-68.34c6.21-16.96,18.15-29,33.4-38.11c38.1-22.75,46.34-72.16,17.63-105.11
		c-22.71-26.07-60.9-28.32-83.32-13.14c-16.28,11.02-34.72,14.34-53.78,7.78c-22.52-7.75-34.4-24.94-39.82-47.36
		c-2.13-8.82-1.65-17.92-2.99-26.82c-4.96-32.92-35.88-59.45-73.48-55.98c-32.86,3.04-59.83,32.8-59.55,66.82
		c0.05,5.94-0.39,11.83-1.39,17.68c-5.2,30.3-29.66,50.81-59.8,49.97c-13.42-0.38-25.52-5.13-37.19-11.5
		c-45.06-24.55-96.95,8.86-98.18,57.58c-0.67,26.58,11.47,46.7,33.98,60.52c8.18,5.02,15.73,10.69,21.96,18.04
		c19.63,23.16,20.27,54.98,1.54,78.47c-6.35,7.96-14.29,13.95-23.07,19.04c-26.8,15.54-40.06,46.67-32.57,76.02
		c11.35,44.47,60.27,64.85,100.02,41.87c20.51-11.86,41.97-14.23,63.23-2.62c22.65,12.37,31.92,32.88,31.83,58.08
		c-0.02,6.08,0.32,12.1,1.71,18.01C342.66,679.22,368.54,698.77,397.63,698.69z"/>
</motion.svg>
    <div className='hero-style-container absolute w-3/4'>
		<Hero_style />
    </div>
      
	  <Hero_text/>
      
	<div 
	  
	  className="hero-logo-container">
        <motion.img 
		initial={{ opacity: 0, y: 50 , x: 50}}
		animate={{ opacity: 1, y: 0, x:0 }}
		transition={{ delay:0.6, duration: 0.5 }} 
		src="../images/braintonic_logo.png" alt=''/>
      </div>
    </div>
  )
}

export default Hero_main
