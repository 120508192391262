import React, { useEffect, useState } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { FaWhatsapp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { db, firebase} from '../../FirebaseConfig';
import emailjs from '@emailjs/browser';
const Contact_sendEmail = () => {

  const {t} = useTranslation();
  var text = [t("ContactUs.email-title"), t("ContactUs.email-span"), t("ContactUs.email-desc-1"), t("ContactUs.email-desc-2"),t("ContactUs.fname")
  ,t("ContactUs.lname"), t("ContactUs.email"), t("ContactUs.message"), t("ContactUs.send-email")];
  
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    message: '',
  });

  const [links, setLinks] = useState([]);  

  const getlinks = async () => {
    try{
      const collectionRef = db.collection("sociallinks");
      const snapshot = await collectionRef.get();
    
      const documents = [];
      snapshot.forEach(doc => {
        documents.push({ id: doc.id, ...doc.data() });
      });
    
      setLinks(documents)
    } catch (error) {
      console.error('Error fetching Social Links:', error);
    }
  };

  useEffect(() => {
    getlinks();
  },[]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
  e.preventDefault();

  const serviceId = 'service_wip8w1f';
  const publicKey = '69EuZRY20TRkD9Auy';
  const templateId = 'template_ojt2zek';

  const SendData = {
    from_name:  `${formData.fname} ${formData.lname}`,
    from_email:  formData.email,
    message: formData.message,
  };

  emailjs.send(serviceId, templateId, SendData, publicKey)
  .then((result) => {
    alert(`Email successfully sent!`)
    const emailData = {
      firstName: formData.fname,
      lastName: formData.lname,
      email: formData.email,
      message: formData.message,
      timestamp: firebase.firestore.FieldValue.serverTimestamp() // For Firestore
      // timestamp: firebase.database.ServerValue.TIMESTAMP // For Realtime Database
    };

    db.collection("emailMessages").add(emailData)
  .then(() => console.log("Data added to Firebase"))
  .catch((error) => console.error("Error adding document: ", error));
  }, (error) => {
    alert(`Failed to send Email! ${error}`)
  });

    setFormData({
      fname: '',
      lname: '',
      email: '',
      message: '',
    })
  };  

const [ref, inView] = useInView({ triggerOnce: true });
const controllogo = useAnimation();  
const controltitle = useAnimation(); 
const controlform = useAnimation(); 

useEffect(() => {
    if (inView) {
        controllogo.start({
            x:0,
            opacity:1,
            transition:{delay:0.4, duration:0.8, type:"spring"}
        });
        controltitle.start({
          x:0,
          opacity:1,
          transition:{delay:0.8, duration:0.8, type:"spring"}
      });
      controlform.start({
        x:0,
        opacity:1,
        transition:{delay:1.2, duration:0.8, type:"spring"}
    });
    }
}, [controllogo, controltitle, controlform ,inView]);

  return (
    <div className="send-email-container flex gap-10 p-20 overflow-hidden">
        <div className="send-email-info-container">
          <motion.h3
          ref={ref}
          initial={{x:-150, opacity:0}}
          animate={controllogo}
          >{text[0]}</motion.h3>
          <motion.p
          ref={ref}
          initial={{ x:-150, opacity:0 }}
          animate={controltitle}
          ><span>{text[1]}</span>{text[2]}</motion.p>
          <motion.p
          ref={ref}
          initial={{ x:-150, opacity:0 }}
          animate={controltitle}
          >{text[3]}
          </motion.p>
          {
            links && (<div className='w-full flex'>
              <motion.a 
              ref={ref}
              initial={{opacity:0, x:-70}}
              animate={controltitle}
              href={(links[4]) ? links[4].url : "" } id="whatsapp_link" 
              className='social-media-links w-14 h-14 flex justify-center items-center'><FaWhatsapp size={21}/></motion.a>
            </div>)
          }
        </div>
        <motion.div 
         ref={ref}
         initial={{ x:150, opacity:0 }}
         animate={controlform}
        className="form-container">
          <form action="" onSubmit={handleSubmit} className="w-full h-full flex flex-col">
            <div className="flex w-full gap-3">
              <input type="text" name="fname" value={formData.fname} onChange={handleChange} placeholder={text[4]} required/>
              <input type="text" name="lname" value={formData.lname} onChange={handleChange} placeholder={text[5]} required/>
            </div>
            <div>
            <input className="mt-3" type="email" value={formData.email} onChange={handleChange} name="email" id="email" placeholder={text[6]} required/>
            </div>
            <textarea className="mt-3" name="message" value={formData.message} onChange={handleChange} placeholder={text[7]}></textarea>
            <button className="pink-bg form-button shadow-md mt-3 w-max" type="submit">{text[8]}</button>
          </form>
        </motion.div>
      </div>
  )
}

export default Contact_sendEmail
