import React, { useEffect, useState } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { SiYoutube, SiSpotify  } from "react-icons/si";
import {Autoplay} from 'swiper/modules';
import { db } from '../../FirebaseConfig';

const Brain_Hero = () => {

  const [images, setImages] = useState([]);
  const [links, setLinks] = useState([]);
  const getbrainImages = async () => {
    try{
        const collectionRef = db.collection("brainTitleImages");
        const snapshot = await collectionRef.get();
      
        const documents = [];
        snapshot.forEach(doc => {
          documents.push({ id: doc.id, ...doc.data() });
        });
      
        setImages(documents)
      } catch (error) {
        console.error('Error fetching Brain Title Images:', error);
      }
    };

  const getlinks = async () => {
      try{
        const collectionRef = db.collection("sociallinks");
        const snapshot = await collectionRef.get();
      
        const documents = [];
        snapshot.forEach(doc => {
          documents.push({ id: doc.id, ...doc.data() });
        });
      
        setLinks(documents)
      } catch (error) {
        console.error('Error fetching Social Links:', error);
      }
    };
  
    useEffect(() => {
      getbrainImages();
      getlinks();
    },[]);
 
const [ref, inView] = useInView({ triggerOnce: true });
const controllogo = useAnimation();  

useEffect(() => {
    if (inView) {
        controllogo.start({
            scale:1,
            opacity:1,
            transition:{delay:0.4, duration:0.8, type:"spring"}
        });
    }
}, [controllogo, inView]);

  return (

    <motion.div
    ref={ref}
    initial={{ scale:0.8, opacity:0 }}
    animate={controllogo}
    className='pb-5 relative'
    >
      <Swiper
        grabCursor={true}
        modules={[Autoplay]}
        className=""
        autoplay={true}
      >
        {images && links && images.map((image, index) => (
                  <SwiperSlide key={index} className=''>
                    <img src={image.image_url} alt="" />
                  {(links[3] && links[5]) ? (<div className='absolute w-full flex justify-center items-center gap-10 button-container'>
                    <a href={links[3].url} className='brainButtons spotify-icon dark-green-bg shadow-md rounded-full p-4'><SiSpotify color='white'/></a>
                    <a href={links[5].url} className='brainButtons youtube-icon orange-bg shadow-md rounded-full p-4'><SiYoutube color='white'/></a>
                  </div>):(<></>)}
                  </SwiperSlide>
        ))}
      </Swiper>
      
    </motion.div>
  )
}

export default Brain_Hero
