import React, { useState, useEffect } from 'react';
import { db, storage } from '../../FirebaseConfig';

const BrainNewsTitleImages = () => {
const [brainImages, setbrainImages] = useState([]);
const [showAddModal, setShowAddModal] = useState(false);
const [showUpdateModal, setShowUpdateModal] = useState(false);
const [selectedBrain, setSelectedBrain] = useState(null);
const [newBrainName, setNewBrainName] = useState('');
const [newBrainImage, setNewBrainImage] = useState(null);
const [imageUploadProgress, setImageUploadProgress] = useState(0);

const openAddModal = () => setShowAddModal(true);
const closeAddModal = () => setShowAddModal(false);

const openUpdateModal = (Brain) => {
  setSelectedBrain(Brain);
  setShowUpdateModal(true);
};

const closeUpdateModal = () => {
  setSelectedBrain(null);
  setShowUpdateModal(false);
};

const handleAddBrainImage = async () => {
    try {
      // Step 1: Upload the image to Firebase Storage
      const storageRef = storage.ref();
      const imageRef = storageRef.child(`brainTitle_images/${newBrainName}_${newBrainImage.name}`);
      const uploadTask = await imageRef.put(newBrainImage);
  
      // Step 2: Get the download URL
      const downloadURL = await uploadTask.ref.getDownloadURL();
  
      // Step 3: Add a new document to Firestore
      const brainData = {
        brain_name: newBrainName,
        image_name: `${newBrainName}_${newBrainImage.name}`,
        image_url: downloadURL
      };
  
      await db.collection('brainTitleImages').add(brainData);
      console.log('Brain Title Images added successfully:', brainData);
    } catch (error) {
      console.error('Error adding Brain Title Images:', error);
    }

    getbrainImages();
    setNewBrainName("");
    setNewBrainImage(null);
    closeAddModal();
  };

  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    setSelectedBrain((prev) => ({
      ...prev,
      [fieldName]: file ? file.name : null,
      [`${fieldName}_file`]: file, // Store the File object
    }));
  };

const handleUpdateBrainImage = async () => {
  if (!selectedBrain) { return; }
  try {
    // Step 1: Retrieve the current image URL
    let currentImageUrl = selectedBrain.image_url;
  
    // Step 2: Check if a new image is provided and upload it
    let imageUrl = currentImageUrl;
    if (selectedBrain.image_name_file) {
      const storageRef = storage.ref();
      const imageRef = storageRef.child(`brainTitle_images/${selectedBrain.brain_name}_${selectedBrain.image_name_file.name}`);
      const uploadTask = await imageRef.put(selectedBrain.image_name_file);
  
      // Get the download URL of the new image
      imageUrl = await uploadTask.ref.getDownloadURL();
    }
  
    // Step 3: Update partner data in Firestore
    const brainData = {
      brain_name: selectedBrain.brain_name,
      image_name: `${selectedBrain.brain_name}_${selectedBrain.image_name_file.name}`,
      image_url: imageUrl
    };
  
    await db.collection('brainTitleImages').doc(selectedBrain.id).update(brainData);
    console.log(`Brain Title Image with ID ${selectedBrain.id} has been updated.`);
  
    // Step 4: Delete the old image if a new image was uploaded
    if (imageUrl !== currentImageUrl) {
      const oldImageRef = storage.refFromURL(currentImageUrl);
      await oldImageRef.delete();
      console.log('Old image has been deleted.');
    }
  
  } catch (error) {
    console.error('Error updating Brain Title Image:', error);
  }
  
  getbrainImages();
  setSelectedBrain(null);
  setShowUpdateModal(false);
  closeUpdateModal();
  
  };

  const handleDeleteImage = async (brainImageId, imageName) => {
    try {
      // Step 1: Delete the partner from Firestore
      await db.collection('brainTitleImages').doc(brainImageId).delete();
      console.log(`Partner with ID ${brainImageId} deleted from Firestore.`);
  
      // Step 2: Delete the image from Firebase Storage
      const imageRef = storage.ref(`brainTitle_images/${imageName}`);
      await imageRef.delete();
      console.log(`Image ${imageName} deleted from Firebase Storage.`);
    } catch (error) {
      console.error('Error deleting partner:', error);
    }

    getbrainImages();
  };

const getbrainImages = async () => {
try{
    const collectionRef = db.collection("brainTitleImages");
    const snapshot = await collectionRef.get();
  
    const documents = [];
    snapshot.forEach(doc => {
      documents.push({ id: doc.id, ...doc.data() });
    });
  
    setbrainImages(documents)
  } catch (error) {
    console.error('Error fetching Brain Title Images:', error);
  }
};

  useEffect(() => {
    getbrainImages();
  },[]);

  return (
    <div className="container mx-auto p-4 relative">
      <h1 className="text-2xl font-bold mb-4">Brain Title Images</h1>

      {/* Display Partners */}
      {brainImages && (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {brainImages.map((brainImage) => (
            <div key={brainImage.id} className="bg-white p-4 rounded shadow">
                <img src={brainImage.image_url} alt={brainImage.brain_name} className="w-full object-fill mb-2 rounded" />
                <p className="text-lg font-semibold">{brainImage.brain_name}</p>
                <div className="flex justify-end mt-2">
                <button onClick={() => openUpdateModal(brainImage)} className="mr-2 text-blue-500">
                    Update
                </button>
                <button className="text-red-500" onClick={() => handleDeleteImage(brainImage.id, brainImage.image_name)} >
                    Delete
                </button>
                </div>
            </div>
            ))}
        </div>
      )}


      {/* Button to open Add Partner Modal */}
      <div className="absolute bottom-4 right-4">
        <button onClick={() => {setShowAddModal(true)}} className="bg-green-500 text-white py-2 px-4 rounded">
          Add Image
        </button>
      </div>


      {/* Add Partner Modal */}
      {showAddModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow w-96">
            <h2 className="text-2xl font-bold mb-4">Add Brain Title Image</h2>
            <label className="block mb-2">Name:</label>
            <input
              type="text"
              value={newBrainName}
              onChange={(e) => setNewBrainName(e.target.value)}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Image:</label>
            <input
              type="file"
              onChange={(e) => setNewBrainImage(e.target.files[0])}
              className="w-full p-2 border rounded mb-4"
            />
            {imageUploadProgress > 0 && (
              <progress value={imageUploadProgress} max="100" className="w-full mb-4"></progress>
            )}
            <button onClick={handleAddBrainImage} className="mx-2 bg-blue-500 text-white py-2 px-4 rounded">
              Add Image
            </button>
            <button onClick={closeAddModal} className="mt-4 bg-gray-500 text-white py-2 px-4 rounded">
              Close
            </button>
          </div>
        </div>
      )}


      {/* Update Partner Modal */}
      {showUpdateModal && selectedBrain && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow w-96">
            <h2 className="text-2xl font-bold mb-4">Update Brain Image</h2>
            <label className="block mb-2">Name:</label>
            <input
              type="text"
              value={selectedBrain.brain_name}
              onChange={(e) => setSelectedBrain({ ...selectedBrain, brain_name: e.target.value })}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Image: {selectedBrain.image_name}</label>
            <input
              type="file"
              onChange={(e) => handleFileChange(e, 'image_name')}
              className="w-full p-2 border rounded mb-4"
            />
            {/* Add any additional fields you want to update */}

            {/* Button to trigger the update */}
            <button onClick={() => handleUpdateBrainImage(selectedBrain)} className="mx-2 bg-blue-500 text-white py-2 px-4 rounded">
              Update Image
            </button>
            <button onClick={closeUpdateModal} className="mt-4 bg-gray-500 text-white py-2 px-4 rounded">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BrainNewsTitleImages;
