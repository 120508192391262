import React, { useState, useEffect } from 'react';
import { db } from '../../FirebaseConfig';
const SocialLinks = () => {

  const [links, setLinks] = useState([]);  
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedSocial, setSelectedSocial] = useState(null);

  const getlinks = async () => {
    try{
      const collectionRef = db.collection("sociallinks");
      const snapshot = await collectionRef.get();
    
      const documents = [];
      snapshot.forEach(doc => {
        documents.push({ id: doc.id, ...doc.data() });
      });
    
      setLinks(documents)
    } catch (error) {
      console.error('Error fetching Social Links:', error);
    }
  };

  useEffect(() => {
    getlinks();
  },[]);

  const openUpdateModal = (social) => {
    setSelectedSocial(social);
    setShowUpdateModal(true);
  };

  const closeUpdateModal = () => {
    setSelectedSocial(null);
    setShowUpdateModal(false);
  };

  const handleUpdateSocial = async () => {
    if (!selectedSocial) { return; }
    try {
    
      // Step 3: Update partner data in Firestore
      const socialData = {
        url: selectedSocial.url
      };
    
      await db.collection('sociallinks').doc(selectedSocial.id).update(socialData);
      console.log(`Social Link: with ID ${selectedSocial.id} has been updated.`);
    
    } catch (error) {
      console.error('Error updating Social Link:', error);
    }
    
    getlinks();
    closeUpdateModal();
  };

  return (
     <div className="container mx-auto p-4 relative">
      <h1 className="text-2xl font-bold mb-4">Social Media Links</h1>
      {/* Button to open Add Testimonial Modal */}
      {/* Display Testimonials */}
      {links && (
        <div className="flex flex-col gap-5">
          {links.map((link) => (
            <div key={link.id} className="bg-white p-4 rounded shadow ">
                <p className="text-lg mt-3 font-semibold">{link.id}</p>
                <p className="text-md mt-3">{link.url}</p>
                <div className="flex justify-end mt-2">
                 <button onClick={() => {openUpdateModal(link)}} className="mr-2 text-blue-500">
                    Update
                </button> 
                </div> 
            </div>
            ))}
        </div>
      )}

      {/* Update Partner Modal */}
      {showUpdateModal && selectedSocial && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow w-2/3">
          <h2 className="text-2xl font-bold mb-4">Update {selectedSocial.id}</h2>
            <label className="block mb-2">Link:</label>
            <input
              type="text"
              value={selectedSocial.url}
              onChange={(e) => setSelectedSocial({ ...selectedSocial, url: e.target.value })}
              className="w-full p-2 border rounded mb-4"
            />
            <button onClick={() => handleUpdateSocial()} className="mx-2 bg-blue-500 text-white py-2 px-4 rounded">
              Update Social
            </button>
            <button onClick={closeUpdateModal} className="mt-4 bg-gray-500 text-white py-2 px-4 rounded">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default SocialLinks
