"use client"

import React, { useEffect } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';

const Service_desc = ({service_type, translations}) => {

    const [ref, inView] = useInView({ triggerOnce: true });
    const coloredDiv = useAnimation();  
    const pinkDiv = useAnimation();    

    useEffect(() => {
        if (inView) {
            coloredDiv.start({
                scale:1,
                y:0,
                opacity:1,
                transition:{delay:0.5, duration:0.8, type:"spring"}
            });
            pinkDiv.start({
              x:0,
              opacity:1,
              transition:{delay:1, duration:1, type:"spring"}
          }); 
        }
      }, [pinkDiv, coloredDiv, inView]);

  return (
    <div className='service-desc-conatiner'>
        {service_type === "Emotional" && (
            <motion.div 
            ref={ref}
            initial={{ opacity:0, scale:0.7, y:-150 }}
            animate={coloredDiv}
            className="colored-desc blue-bg">
            <p>{translations[0]}</p>
        </motion.div>
        )}
        {service_type === "Habit" && (
            <motion.div 
            ref={ref}
            initial={{ opacity:0, scale:0.7, y:-150 }}
            animate={coloredDiv}
            className="colored-desc orange-bg">
            <p>{translations[0]}</p>
        </motion.div>
        )}
        {service_type === "Holistic" && (
            <motion.div 
            ref={ref}
            initial={{ opacity:0, scale:0.7, y:-150 }}
            animate={coloredDiv}
            className="colored-desc pink-bg hol">
            <p>{translations[0]}</p>
        </motion.div>
        )}
        {service_type === "Sustainable" && (
            <motion.div 
            ref={ref}
            initial={{ opacity:0, scale:0.7, y:-150 }}
            animate={coloredDiv}
            className="colored-desc dark-green-bg">
            <p>{translations[0]}</p>
        </motion.div>
        )}
        <motion.div 
        ref={ref}
        initial={{ opacity:0, x:-200 }}
        animate={pinkDiv}
        className="pink-desc-container flex pink-bg">
            <div className="arrow-div">
                <img src="/images/target-arrow.png" alt="" />
            </div>
            <div className="pink-desc">
                <h2>{translations[1]}</h2>
                <p>{translations[2]}</p>
                <p>{translations[3]}</p>
            </div>
        </motion.div>
    </div>
  )
}

export default Service_desc
