import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import { FaQuoteLeft } from "react-icons/fa6";
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { FaQuoteRight } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';
import { db } from '../../FirebaseConfig';


const Hero_testimonials = () => {
  const {t} = useTranslation();
  var T = t("Index.testimonials");
  const ref = useRef();
  const [ref1, inView] = useInView({ triggerOnce: true });
  const controls = useAnimation();

    const [swiper, setSwiper] = useState(null);

    const onSwiper = (swiperInstance) => {
        setSwiper(swiperInstance);
    };
    const [testimonials, setTestimonials] = useState([]);
    const { i18n } = useTranslation();
    const currentLang = i18n.language;
    
    const getTestimonials = async () => {
      try{
        const collectionRef = db.collection("homeTestimonials");
        const snapshot = await collectionRef.get();
      
        const documents = [];
        snapshot.forEach(doc => {
          documents.push({ id: doc.id, ...doc.data() });
        });
      
        setTestimonials(documents)
      } catch (error) {
        console.error('Error fetching Home Testimonials:', error);
      }
    };

    useEffect(() => {
      getTestimonials();
    },[]);

   useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  },[controls,inView]);

  

  const variants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };


return (
<motion.div 
ref={ref1}
initial="hidden"
animate={controls}
variants={variants} className="testimonials mt-16">
      <div className='flex flex-col justify-center items-center relative'>
      <h1 className=''>{T}</h1>
      
      <Swiper
                spaceBetween={80}
                ref={ref}
                autoplay={{ delay: 3000 }}
                loop={true}
                className="mySwiper w-11/12 h-fit"
                onSwiper={onSwiper} // Callback to get the swiper instance
            >
                
                {testimonials && testimonials.map((testimonial, index) => (
                  <SwiperSlide key={index}>
                  <div className="flex justify-center items-center p-10">
                    <div className="testimonial w-11/12 flex justify-center items-center gap-10">
                  
                      <div className="testimonial-logo-container">
                        <img src={testimonial.image_url} alt="" />
                      </div> 
                      
                      <p className="testimonial-text">{(currentLang === "es") ? testimonial.text_es : testimonial.text_en}</p>
                    </div>
                    <FaQuoteLeft className="upper-quote" />
                    <FaQuoteRight className="lower-quote" />
                  </div> 
                  </SwiperSlide>
                ))}
                
            </Swiper>
            {swiper && (
            <div className='z-50'>
                <button className="testimonial-next-btn" onClick={() => swiper.slideNext()}><GrNext size={43} /></button>
                <button className="testimonial-previous-btn " onClick={() => swiper.slidePrev()}><GrPrevious size={43} /></button>
            </div>
        )}
      </div>
    </motion.div>
  )
}

export default Hero_testimonials


