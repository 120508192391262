import React, { useState, useEffect } from 'react';
import { db, storage } from '../../FirebaseConfig';

const HomePageTestimonials = () => {

//   const [partners, loading, error] = useCollectionData(partnersRef, { idField: 'id' });
const [testimonials, setTestimonials] = useState([]);

const getTestimonials = async () => {
  try{
    const collectionRef = db.collection("homeTestimonials");
    const snapshot = await collectionRef.get();
  
    const documents = [];
    snapshot.forEach(doc => {
      documents.push({ id: doc.id, ...doc.data() });
    });
  
    setTestimonials(documents)
  } catch (error) {
    console.error('Error fetching Home Testimonials:', error);
  }
};

const handleFileChange = (e, fieldName) => {
  const file = e.target.files[0];
  setSelectedTestimonial((prev) => ({
    ...prev,
    [fieldName]: file ? file.name : null,
    [`${fieldName}_file`]: file, // Store the File object
  }));
};

useEffect(() => {
  getTestimonials();
},[]);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [newTestimonialTexten, setNewTestimonialTexten] = useState('');
  const [newTestimonialTextes, setNewTestimonialTextes] = useState('');
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  const [newTestimonialName, setNewTestimonialName] = useState('');
  const [newTestimonialImage, setNewTestimonialImage] = useState(null);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);

  const openAddModal = () => setShowAddModal(true);
  const closeAddModal = () => setShowAddModal(false);

  const openUpdateModal = (testimonial) => {
    setSelectedTestimonial(testimonial);
    setShowUpdateModal(true);
  };

  const closeUpdateModal = () => {
    setSelectedTestimonial(null);
    setShowUpdateModal(false);
  };

  const handleUpdateTestimonial = async () => {
    if (!selectedTestimonial) { return; }
    try {
      // Step 1: Retrieve the current image URL
      let currentImageUrl = selectedTestimonial.image_url;
      var imageName = selectedTestimonial.image_name;
      // Step 2: Check if a new image is provided and upload it
      let imageUrl = currentImageUrl;
      if (selectedTestimonial.image_name_file) {
        const storageRef = storage.ref();
        const imageRef = storageRef.child(`homeTestimonial_images/${selectedTestimonial.name}_${selectedTestimonial.image_name_file.name}`);
        const uploadTask = await imageRef.put(selectedTestimonial.image_name_file);
        imageName = `${selectedTestimonial.name}_${selectedTestimonial.image_name_file.name}`;
        // Get the download URL of the new image
        imageUrl = await uploadTask.ref.getDownloadURL();
      }
    
      // Step 3: Update partner data in Firestore
      const testimonialData = {
        name: selectedTestimonial.name,
        text_en: selectedTestimonial.text_en,
        text_es: selectedTestimonial.text_es,
        image_name: imageName,
        image_url: imageUrl
      };
    
      await db.collection('homeTestimonials').doc(selectedTestimonial.id).update(testimonialData);
      console.log(`Home Testimonial with ID ${selectedTestimonial.id} has been updated.`);
    
      // Step 4: Delete the old image if a new image was uploaded
      if (imageUrl !== currentImageUrl) {
        const oldImageRef = storage.refFromURL(currentImageUrl);
        await oldImageRef.delete();
        console.log('Old image has been deleted.');
      }
    
    } catch (error) {
      console.error('Error updating Home Testimonial:', error);
    }
    
    getTestimonials();
    setSelectedTestimonial(null);
    closeUpdateModal();
    
  };

  const handleAddTestimonial = async () => {
    try {
      // Step 1: Upload the image to Firebase Storage
      const storageRef = storage.ref();
      const imageRef = storageRef.child(`homeTestimonial_images/${newTestimonialName}_${newTestimonialImage.name}`);
      const uploadTask = await imageRef.put(newTestimonialImage);
  
      // Step 2: Get the download URL
      const downloadURL = await uploadTask.ref.getDownloadURL();
  
      // Step 3: Add a new document to Firestore
      const testimonialData = {
        name: newTestimonialName,
        text_en: newTestimonialTexten,
        text_es: newTestimonialTextes,
        image_name: `${newTestimonialName}_${newTestimonialImage.name}`,
        image_url: downloadURL
      };
  
      await db.collection('homeTestimonials').add(testimonialData);
  
      console.log('Home testimonial added successfully:', testimonialData);
    } catch (error) {
      console.error('Error adding Home testimonial:', error);
    }

    getTestimonials();
    setNewTestimonialName("");
    setNewTestimonialImage(null);
    setNewTestimonialTexten("");
    setNewTestimonialTextes("");
    closeAddModal();
  };

  const handleDeleteTestimonial = async (testimonial) => {
    try {
      const imageName = testimonial.image_name
      // Step 1: Delete the partner from Firestore
      await db.collection('homeTestimonials').doc(testimonial.id).delete();
      console.log(`Home Testimonial with ID ${testimonial.id} deleted from Firestore.`);
  
      // Step 2: Delete the image from Firebase Storage
      const imageRef = storage.ref(`homeTestimonial_images/${imageName}`);
      await imageRef.delete();
      console.log(`Image ${imageName} deleted from Firebase Storage.`);
    } catch (error) {
      console.error('Error deleting partner:', error);
    }

    getTestimonials();
  };

  return (
    <div className="container mx-auto p-4 relative">
      <h1 className="text-2xl font-bold mb-4">Home Testimonials</h1>
      {/* Button to open Add Testimonial Modal */}
      <div className="absolute top-4 right-4">
        <button onClick={openAddModal} className="bg-green-500 text-white py-2 px-4 rounded">
          Add Testimonial
        </button>
      </div>
      {/* Display Testimonials */}
      {testimonials && (
        <div className="flex flex-col gap-5">
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} className="bg-white p-4 rounded shadow">
                <img src={testimonial.image_url} alt={testimonial.name} className="w-1/4 object-fill mb-2 rounded" />
                <p className="text-lg font-semibold">{testimonial.name}</p>
                <p className="text-lg">{testimonial.text_en}</p>
                <p className="text-lg">{testimonial.text_es}</p>
                <div className="flex justify-end mt-2">
                <button onClick={() => openUpdateModal(testimonial)} className="mr-2 text-blue-500">
                    Update
                </button>
                <button onClick={() => handleDeleteTestimonial(testimonial)} className="text-red-500">
                    Delete
                </button>
                </div>
            </div>
            ))}
        </div>
      )}

      {/* Add Testimonial Modal */}
      {showAddModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow w-3/4">
            <h2 className="text-2xl font-bold mb-4">Add Testimonial</h2>
            <label className="block mb-2">Name:</label>
            <input
              type="text"
              value={newTestimonialName}
              onChange={(e) => setNewTestimonialName(e.target.value)}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Text (English)</label>
            <input
              type="text"
              value={newTestimonialTexten}
              onChange={(e) => setNewTestimonialTexten(e.target.value)}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Text (Spanish)</label>
            <input
              type="text"
              value={newTestimonialTextes}
              onChange={(e) => setNewTestimonialTextes(e.target.value)}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Image:</label>
            <input
              type="file"
              onChange={(e) => setNewTestimonialImage(e.target.files[0])}
              className="w-full p-2 border rounded mb-4"
            />
            {imageUploadProgress > 0 && (
              <progress value={imageUploadProgress} max="100" className="w-full mb-4"></progress>
            )}
            <button onClick={handleAddTestimonial} className="mx-5 bg-blue-500 text-white py-2 px-4 rounded">
              Add Testimonial
            </button>
            <button onClick={closeAddModal} className="mt-4 bg-gray-500 text-white py-2 px-4 rounded">
              Close
            </button>
          </div>
        </div>
      )}

      {/* Update Partner Modal */}
      {showUpdateModal && selectedTestimonial && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow w-2/3">
            <h2 className="text-2xl font-bold mb-4">Update Testimonial</h2>
            <label className="block mb-2">Name:</label>
            <input
              type="text"
              value={selectedTestimonial.name}
              onChange={(e) => setSelectedTestimonial({ ...selectedTestimonial, name: e.target.value })}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Text (English)</label>
            <input
              type="text"
              value={selectedTestimonial.text_en}
              onChange={(e) => setSelectedTestimonial({ ...selectedTestimonial, text_en: e.target.value })}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Text (Spanish)</label>
            <input
              type="text"
              value={selectedTestimonial.text_es}
              onChange={(e) => setSelectedTestimonial({ ...selectedTestimonial, text_es: e.target.value })}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Image: {selectedTestimonial.image_name}</label>
            <input
              type="file"
              onChange={(e) => handleFileChange(e, 'image_name')}
              className="w-full p-2 border rounded mb-4"
            />
            {/* Add any additional fields you want to update */}

            {/* Button to trigger the update */}
            <button onClick={() => handleUpdateTestimonial()} className="mx-2 bg-blue-500 text-white py-2 px-4 rounded">
              Update Testimonial
            </button>
            <button onClick={closeUpdateModal} className="mt-4 bg-gray-500 text-white py-2 px-4 rounded">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePageTestimonials;
