import React, { useEffect, useState } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { db } from '../../FirebaseConfig';
const Service_testimonial = ({service_type}) => {

  const [ref1, inView] = useInView({ triggerOnce: true });
  const controllogo = useAnimation();  
  const [tests, setTests] = useState([]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const getServiceTestimonials = async () => {
    try{
      const collectionRef = db.collection("serviceTestimonials");
      const snapshot = await collectionRef.get();
    
      const documents = [];
      snapshot.forEach(doc => {
        documents.push({ id: doc.id, ...doc.data() });
      });
    
      setTests(documents)
    } catch (error) {
      console.error('Error fetching Service Testimonials:', error);
    }
  };
  
  
    useEffect(() => {
      getServiceTestimonials();
    },[]);

useEffect(() => {
    if (inView) {
        controllogo.start({
            y:0,
            opacity:1,
            scale: 1,
            transition:{delay:1, duration:1, type:"spring"}
        });
    }
}, [controllogo,inView]);

  return (
    <motion.div 
    ref={ref1}
    initial={{ y:-200, scale:0.6, opacity:0 }}
    animate={controllogo} className='testimonial-conatiner mb-20'>  
      {tests && tests.map((testimonial, index) => (
                  
      <div className="flex" key={index}>
        {(service_type === testimonial.id) && (
        <div className='flex'>
          <div className='test-image flex-1 pl-20 pr-10'>
          <div className="test-image-div rounded-full mt-5 mb-5 relative">
            <img src={testimonial.image_url}/>
            {service_type === "Corporate" && (<div className="test-image-inner-1 absolute -top-4 -left-4 -right-4 -bottom-4 rounded-full opacity-80"></div>)}
            {service_type === "Emotional" && (<div className="test-image-inner-2 absolute -top-4 -left-4 -right-4 -bottom-4 rounded-full opacity-80"></div>)}
            {service_type === "Habit" && (<div className="test-image-inner-5 absolute -top-4 -left-4 -right-4 -bottom-4 rounded-full opacity-80"></div>)}
            {service_type === "Holistic" && (<div className="test-image-inner-4 absolute -top-4 -left-4 -right-4 -bottom-4 rounded-full opacity-80"></div>)}
            {service_type === "Sustainable" && (<div className="test-image-inner-3 absolute -top-4 -left-4 -right-4 -bottom-4 rounded-full opacity-80"></div>)}
          </div>
        </div>
        <div className="test-desc-div flex flex-col flex-1 pr-20 pl-10 pt-10">
        <div className="test-title-div w-4/6">
          <img src={testimonial.logo_url} alt="" />
          <h2>{(currentLanguage === "es") ? "TESTIMONIO" : "TESTIMONIAL"}</h2>
        </div>
        <p>{(currentLanguage === "es") ? testimonial.desc_es : testimonial.desc_en}</p>
      </div>
        </div>
        )}
      </div> 
              
      ))}
    </motion.div >


  )
}

export default Service_testimonial
