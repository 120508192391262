import React, { useEffect } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import TypeformComp from '../HomePage/TypeformComp';
import { useTranslation } from 'react-i18next';

const Service_title = ({service_type}) => {
  const {t} = useTranslation();
  var translations = [t(`${service_type}.title`), t(`${service_type}.quote`), t(`${service_type}.author`)];
  if(service_type === "Holistic")
  {
    translations = [t(`${service_type}.title`), t(`${service_type}.quote`), t(`${service_type}.author`), 
    t(`${service_type}.extra-quote-span-1`), t(`${service_type}.extra-quote-span-2`), t(`${service_type}.extra-quote-span-3`)];
  }
  if(service_type === "AboutUs")
  {
    translations = [t(`${service_type}.title`), t(`${service_type}.content`)];
  }
  const [ref, inView] = useInView({ triggerOnce: true });
  const controlHeading = useAnimation();
  const controlImage = useAnimation();
  const controlImage2 = useAnimation();
  const controlquote = useAnimation();
  useEffect(() => {
    if (inView) {
      controlHeading.start({
        x:0,
        opacity:1,
        transition: {delay:0.8, duration:1}
      });
      controlquote.start({
        x:0,
        opacity:1,
        transition: {delay:1.2, duration:1}
      });
      controlImage.start({
        y:0,
        opacity:1,
        zIndex:-1,
        transition: {duration:0.8}
      });
      controlImage2.start({
        scale:1,
        y:0, 
        x:0,
        opacity:1,
        zIndex:-1,
        transition: {delay:0.5, duration:0.6}
      });
    }
  },[controlHeading,controlquote,controlImage,inView]);


  return (
    <div className='service-title-container gap-10 flex justify-center items-center relative overflow-hidden'> 
      <div className="title-image-container relative">
      {service_type === "Emotional" && (
        <motion.div 
        ref={ref}
        initial={{x:-350, opacity:0}}
        animate={controlHeading}
        className="service-title-div shadow-md light-blue-bg">
        <h1 className='blue-bg'>{translations[0]}</h1>
      </motion.div> 
      )}
      {service_type === "Habit" && (
       <motion.div 
       ref={ref}
       initial={{x:-350, opacity:0}}
       animate={controlHeading} className="service-title-div shadow-md yellow-bg">
        <h1 className='orange-bg'>{translations[0]}</h1>
      </motion.div> 
      )}
      {service_type === "Holistic" && (
        <motion.div 
        ref={ref}
        initial={{x:-350, opacity:0}}
        animate={controlHeading}
         className="service-title-div shadow-md light-pink-bg">
        <h1 className='pink-bg'>{translations[0]}</h1>
      </motion.div> 
      )}
      {service_type === "Sustainable" && (
        <motion.div 
        ref={ref}
        initial={{x:-350, opacity:0}}
        animate={controlHeading}
         className="service-title-div shadow-md green-bg">
        <h1 className='dark-green-bg'>{translations[0]}</h1>
      </motion.div> 
      )}
      {service_type === "AboutUs" && (
        <motion.div 
        ref={ref}
        initial={{x:-350, opacity:0}}
        animate={controlHeading}
         className="service-title-div shadow-md light-pink-bg">
        <h1 className='pink-bg'>{translations[0]}</h1>
      </motion.div> 
      )}
        {service_type === "Emotional" && (<motion.img ref={ref}
        initial={{y:-150, opacity:0, zIndex:-1}}
        animate={controlImage} className="" src='/images/emo_300.png'/>)}
        {service_type === "Habit" && (<motion.img ref={ref}
        initial={{y:-150, opacity:0, zIndex:-1}}
        animate={controlImage} className="" src='/images/orange_300.png'/>)}
        {service_type === "Holistic" && (<motion.img ref={ref}
        initial={{y:-150, opacity:0, zIndex:-1}}
        animate={controlImage} className="" src='/images/good_habits_300.png'/>)}
        {service_type === "Sustainable" && (<motion.img ref={ref}
        initial={{y:-150, opacity:0, zIndex:-1}}
        animate={controlImage} className="" src='/images/sust_300.png'/>)}
       {service_type === "AboutUs" && (
    <div className='title-logos'>
        <motion.img ref={ref}
            initial={{ y: -150, opacity: 0, zIndex: -1 }}
            animate={controlImage}
            className="myImage"
            src='/images/logo.png' />

        <motion.img ref={ref}
            initial={{ scale: 0.5, y: -150, x: -150, opacity: 0, zIndex: -1 }}
            animate={controlImage2}
            className="myImage reth"
            src='/images/aboutsUs-title.png' />
    </div>
)}

        
      </div>
      { service_type === "AboutUs" ? (
        <motion.div 
        ref={ref}
        initial={{x:-350, opacity:0}}
        animate={controlquote} className="quote-container">
       <p className='extra-quote mt-5'>{translations[1]}</p>
      </motion.div>
      ) : (
        <motion.div 
        ref={ref}
        initial={{x:-350, opacity:0}}
        animate={controlquote} className="quote-container">
        <p>&quot;{translations[1]}&quot;</p>
        <p className="author">{translations[2]}</p>
        {service_type === "Holistic" && (
          <p className='extra-quote mt-5'>{translations[3]} <span>&quot; {translations[4]} &quot;</span> {translations[5]}</p>
      )}
      {service_type === "Sustainable" && (
          <div className="w-full flex justify-center items-center">
          <div className='w-fit my-bounce-animation'>
          <TypeformComp />
          </div>
          </div>
      )}
      </motion.div>)
      }
    </div>
  )
}

export default Service_title
