import React, { useState, useEffect } from 'react';
import { db, storage } from '../../FirebaseConfig';

const PartnerPanel = () => {
const [partners, setpartners] = useState([]);

const getpartners = async () => {
 try{
    const collectionRef = db.collection("partners");
    const snapshot = await collectionRef.get();
  
    const documents = [];
    snapshot.forEach(doc => {
      documents.push({ id: doc.id, ...doc.data() });
    });
  
    setpartners(documents)
  } catch (error) {
    console.error('Error fetching partners:', error);
  }
};

  useEffect(() => {
    getpartners();
  },[]);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [newPartnerName, setNewPartnerName] = useState('');
  const [newPartnerImage, setNewPartnerImage] = useState(null);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);

  const openAddModal = () => setShowAddModal(true);
  const closeAddModal = () => setShowAddModal(false);

  const openUpdateModal = (partner) => {
    setSelectedPartner(partner);
    setShowUpdateModal(true);
  };

  const closeUpdateModal = () => {
    setSelectedPartner(null);
    setShowUpdateModal(false);
  };

  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    setSelectedPartner((prev) => ({
      ...prev,
      [fieldName]: file ? file.name : null,
      [`${fieldName}_file`]: file, // Store the File object
    }));
  };

  const handleAddPartner = async () => {
    try {
      // Step 1: Upload the image to Firebase Storage
      const storageRef = storage.ref();
      const imageRef = storageRef.child(`partner_images/${newPartnerName}_${newPartnerImage.name}`);
      const uploadTask = await imageRef.put(newPartnerImage);
  
      // Step 2: Get the download URL
      const downloadURL = await uploadTask.ref.getDownloadURL();
  
      // Step 3: Add a new document to Firestore
      const partnerData = {
        partner_name: newPartnerName,
        image_name: `${newPartnerName}_${newPartnerImage.name}`,
        image_url: downloadURL
      };
  
      await db.collection('partners').add(partnerData);
  
      console.log('Partner added successfully:', partnerData);
    } catch (error) {
      console.error('Error adding partner:', error);
    }

    getpartners();
    setNewPartnerName("");
    setNewPartnerImage(null);
    closeAddModal();
  };

  const handleDeletePartner = async (partnerId, imageName) => {
    try {
      // Step 1: Delete the partner from Firestore
      await db.collection('partners').doc(partnerId).delete();
      console.log(`Partner with ID ${partnerId} deleted from Firestore.`);
  
      // Step 2: Delete the image from Firebase Storage
      const imageRef = storage.ref(`partner_images/${imageName}`);
      await imageRef.delete();
      console.log(`Image ${imageName} deleted from Firebase Storage.`);
    } catch (error) {
      console.error('Error deleting partner:', error);
    }

    getpartners();
  };

  const handleUpdatePartner = async () => {
    if (!selectedPartner) { return; }
    try {
      // Step 1: Retrieve the current image URL
      let currentImageUrl = selectedPartner.image_url;
    
      // Step 2: Check if a new image is provided and upload it
      let imageUrl = currentImageUrl;
      if (selectedPartner.image_name_file) {
        const storageRef = storage.ref();
        const imageRef = storageRef.child(`partner_images/${selectedPartner.partner_name}_${selectedPartner.image_name_file.name}`);
        const uploadTask = await imageRef.put(selectedPartner.image_name_file);
    
        // Get the download URL of the new image
        imageUrl = await uploadTask.ref.getDownloadURL();
      }
    
      // Step 3: Update partner data in Firestore
      const partnerData = {
        partner_name: selectedPartner.partner_name,
        image_name: `${selectedPartner.partner_name}_${selectedPartner.image_name_file.name}`,
        image_url: imageUrl
      };
    
      await db.collection('partners').doc(selectedPartner.id).update(partnerData);
      console.log(`Partner with ID ${selectedPartner.id} has been updated.`);
    
      // Step 4: Delete the old image if a new image was uploaded
      if (imageUrl !== currentImageUrl) {
        const oldImageRef = storage.refFromURL(currentImageUrl);
        await oldImageRef.delete();
        console.log('Old image has been deleted.');
      }
    
    } catch (error) {
      console.error('Error updating partner:', error);
    }
    
    getpartners();
    setSelectedPartner(null);
    setShowUpdateModal(false);
    closeUpdateModal();
    
  };

  return (
    <div className="container mx-auto p-4 relative">
      <h1 className="text-2xl font-bold mb-4">Partner List</h1>

      {/* Display Partners */}
      {partners && (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {partners.map((partner) => (
            <div key={partner.id} className="bg-white p-4 rounded shadow">
                <img src={partner.image_url} alt={partner.partner_name} className="w-full object-fill mb-2 rounded" />
                <p className="text-lg font-semibold">{partner.partner_name}</p>
                <div className="flex justify-end mt-2">
                <button onClick={() => openUpdateModal(partner)} className="mr-2 text-blue-500">
                    Update
                </button>
                <button onClick={() => handleDeletePartner(partner.id, partner.image_name)} className="text-red-500">
                    Delete
                </button>
                </div>
            </div>
            ))}
        </div>
      )}

      {/* Add Partner Modal */}
      {showAddModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow w-96">
            <h2 className="text-2xl font-bold mb-4">Add Partner</h2>
            <label className="block mb-2">Name:</label>
            <input
              type="text"
              value={newPartnerName}
              onChange={(e) => setNewPartnerName(e.target.value)}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Image:</label>
            <input
              type="file"
              onChange={(e) => setNewPartnerImage(e.target.files[0])}
              className="w-full p-2 border rounded mb-4"
            />
            {imageUploadProgress > 0 && (
              <progress value={imageUploadProgress} max="100" className="w-full mb-4"></progress>
            )}
            <button onClick={handleAddPartner} className="mx-2 bg-blue-500 text-white py-2 px-4 rounded">
              Add Partner
            </button>
            <button onClick={closeAddModal} className="mt-4 bg-gray-500 text-white py-2 px-4 rounded">
              Close
            </button>
          </div>
        </div>
      )}


      {/* Update Partner Modal */}
      {showUpdateModal && selectedPartner && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow w-96">
            <h2 className="text-2xl font-bold mb-4">Update Partner</h2>
            <label className="block mb-2">Name:</label>
            <input
              type="text"
              value={selectedPartner.partner_name}
              onChange={(e) => setSelectedPartner({ ...selectedPartner, partner_name: e.target.value })}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Image: {selectedPartner.image_name}</label>
            <input
              type="file"
              onChange={(e) => handleFileChange(e, 'image_name')}
              className="w-full p-2 border rounded mb-4"
            />
            {/* Add any additional fields you want to update */}

            {/* Button to trigger the update */}
            <button onClick={() => handleUpdatePartner(selectedPartner)} className="mx-2 bg-blue-500 text-white py-2 px-4 rounded">
              Update Partner
            </button>
            <button onClick={closeUpdateModal} className="mt-4 bg-gray-500 text-white py-2 px-4 rounded">
              Close
            </button>
          </div>
        </div>
      )}

      {/* Button to open Add Partner Modal */}
      <div className="absolute top-4 right-4">
        <button onClick={openAddModal} className="bg-green-500 text-white py-2 px-4 rounded">
          Add Partner
        </button>
      </div>
    </div>
  );
};

export default PartnerPanel;
