import React, { useEffect } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
const AboutUsInfo = () => {

  const {t} = useTranslation();
  var text = [t("AboutUs.info-title"), t("AboutUs.info-desc"), t("AboutUs.info-div-title-1")
  , t("AboutUs.info-div-desc-1"), t("AboutUs.info-div-title-2"), t("AboutUs.info-div-desc-2"), t("AboutUs.info-div-title-3"), t("AboutUs.info-div-desc-3")
  , t("AboutUs.info-div-title-4"), t("AboutUs.info-div-desc-4")];

    const [ref1, inView] = useInView({ triggerOnce: true });
    const controlHeading = useAnimation();
    const controlSubHeading = useAnimation();
    const controldiv1 = useAnimation();
    const controldiv2 = useAnimation();
    const controldiv3 = useAnimation();
    const controldiv4 = useAnimation();

    useEffect(() => {

      if (inView) {
        console.log("InView")
        controlHeading.start({
          x:0,
          opacity:1,
          transition: {delay:0.5, duration:1, type:"spring"}
        });
        controlSubHeading.start({
            x:0,
            opacity:1,
            transition: {delay:0.8, duration:1}
          });
          controldiv1.start({
            y:0,
            opacity:1,
            transition: {delay:1, duration:1}
          });
          controldiv2.start({
            y:0,
            opacity:1,
            transition: {delay:1.5, duration:1}
          });
          controldiv3.start({
            y:0,
            opacity:1,
            transition: {delay:2, duration:1}
          });
          controldiv4.start({
            y:0,
            opacity:1,
            transition: {delay:2.5, duration:1}
          });

      }
    },[controlHeading,controlSubHeading,controldiv1,controldiv2,controldiv3,controldiv4,inView]);


  return (
    <div className="info-container w-full">
        <div className="title-div w-full">
          <motion.h1 ref={ref1}
            initial={{x:-250, opacity:0}}
            animate={controlHeading}
            className="text-center">{text[0]}</motion.h1>
          <motion.h2 
          ref={ref1}
          initial={{x:250, opacity:0}}
          animate={controlSubHeading}
          className="text-center">{text[1]}</motion.h2>
        </div>

        <div className="info-divs text-white">
          <motion.div 
          ref={ref1}
          initial={{y:-250, opacity:0}}
          animate={controldiv1}
          className="info-div shadow-md pink-bg">
            <h3>{text[2]}</h3>
            <div className="info-icon-div bg-white">
              <img src="/svgs/aboutUs-info-1.svg" alt="" />
            </div>
            <p>{text[3]}</p>
          </motion.div>

          <motion.div 
          ref={ref1}
          initial={{y:-250, opacity:0}}
          animate={controldiv2}
           className="info-div shadow-md blue-bg">
            <h3>{text[4]}</h3>
            <div className="info-icon-div bg-white">
              <img src="/svgs/aboutUs-info-2.svg" alt="" />
            </div>
            <p>{text[5]}</p>
          </motion.div>

          <motion.div 
          ref={ref1}
          initial={{y:-250, opacity:0}}
          animate={controldiv3} className="info-div shadow-md green-bg">
            <h3>{text[6]}</h3>
            <div className="info-icon-div bg-white">
              <img src="/svgs/aboutUs-info-3.svg" alt="" />
            </div>
            <p>{text[7]}</p>
          </motion.div>

          <motion.div 
          ref={ref1}
          initial={{y:-250, opacity:0}}
          animate={controldiv4} 
          className="info-div shadow-md orange-bg">
            <h3>{text[8]}</h3>
            <div className="info-icon-div bg-white">
              <img src="/svgs/aboutUs-info-4.svg" alt="" />
            </div>
            <p>{text[9]}</p>
          </motion.div>
        </div>
      </div>
  )
}

export default AboutUsInfo
