import React, { useState, useEffect } from 'react';
import { db, storage } from '../../FirebaseConfig';

const BrainBlogs = () => {

const [blogs, setBlogs] = useState([]);
const [showAddModal, setShowAddModal] = useState(false);
const [newBlogTexten, setNewBlogTexten] = useState('');
const [newBlogTextes, setNewBlogTextes] = useState('');
const [newBlogTitle_en, setNewBlogTitle_en] = useState('');
const [newBlogTitle_es, setNewBlogTitle_es] = useState('');
const [newBlogdesc_en, setNewBlogdesc_en] = useState('');
const [newBlogdesc_es, setNewBlogdesc_es] = useState('');
const [newBlogImage, setNewBlogImage] = useState(null);
const [showUpdateModal, setShowUpdateModal] = useState(false);
const [selectedBlog, setSelectedBlog] = useState(null);
const [imageUploadProgress, setImageUploadProgress] = useState(0);

const getBlogs = async () => {
  try{
    const collectionRef = db.collection("brainblogs");
    const snapshot = await collectionRef.get();
  
    const documents = [];
    snapshot.forEach(doc => {
      documents.push({ id: doc.id, ...doc.data() });
    });
  
    setBlogs(documents)
  } catch (error) {
    console.error('Error fetching Blogs:', error);
  }
};

const openAddModal = () => setShowAddModal(true);
const closeAddModal = () => setShowAddModal(false);

const openUpdateModal = (Blog) => {
  setSelectedBlog(Blog);
  setShowUpdateModal(true);
};

const closeUpdateModal = () => {
  setSelectedBlog(null);
  setShowUpdateModal(false);
};

useEffect(() => {
    getBlogs();
},[]);

const handleDeleteBlog = async (blog) => {
  try {
    // Step 1: Delete the partner from Firestore
    await db.collection('brainblogs').doc(blog.id).delete();
    console.log(`Blog with ID ${blog.id} deleted from Firestore.`);

    // Step 2: Delete the image from Firebase Storage
    const imageRef = storage.ref(`blog_images/${blog.image_name}`);
    await imageRef.delete();
    console.log(`Image ${blog.image_name} deleted from Firebase Storage.`);
  } catch (error) {
    console.error('Error deleting partner:', error);
  }

  getBlogs();
};

const handleFileChange = (e, fieldName) => {
  const file = e.target.files[0];
  setSelectedBlog((prev) => ({
    ...prev,
    [fieldName]: file ? file.name : null,
    [`${fieldName}_file`]: file, // Store the File object
  }));
};

const handleUpdateBlog = async () => {
  if(!selectedBlog){return}
  try {
    // Step 1: Check if a new image is provided and upload it
    let currentImageUrl = selectedBlog.image_url;
    let imageUrl = currentImageUrl;
    var imageName = selectedBlog.image_name;
    if (selectedBlog.image_name_file) {
      const storageRef = storage.ref();
      const imageRef = storageRef.child(`blog_images/${selectedBlog.title_en}_${selectedBlog.image_name_file.name}`);
      const uploadTask = await imageRef.put(selectedBlog.image_name_file);
      imageName = `${selectedBlog.title_en}_${selectedBlog.image_name_file.name}`;
      // Get the download URL of the new image
      imageUrl = await uploadTask.ref.getDownloadURL();
    }

    // Step 2: Update partner data in Firestore
    const blogData = {
      title_en: selectedBlog.title_en,
      text_en: selectedBlog.text_en,
      desc_title_en: selectedBlog.desc_title_en,
      title_es: selectedBlog.title_es,
      text_es: selectedBlog.text_es,
      desc_title_es: selectedBlog.desc_title_es,
      image_name: imageName,
      image_url: imageUrl
    };

    await db.collection('brainblogs').doc(selectedBlog.id).update(blogData);
    console.log(`Blog with ID ${selectedBlog.id} has been updated.`);
  
    if (imageUrl !== currentImageUrl) {
      const oldImageRef = storage.refFromURL(currentImageUrl);
      await oldImageRef.delete();
      console.log('Old image has been deleted.');
    }
  
  } catch (error) {
    console.error('Error updating Blog:', error);
  }

    getBlogs();
    setSelectedBlog(null);
    setShowUpdateModal(false);
    closeUpdateModal();
};

const handleAddBlog = async () => {
  try {
    // Step 1: Upload the image to Firebase Storage
    const storageRef = storage.ref();
    const imageRef = storageRef.child(`blog_images/${newBlogTitle_en}_${newBlogImage.name}`);
    const uploadTask = await imageRef.put(newBlogImage);

    // Step 2: Get the download URL
    const downloadURL = await uploadTask.ref.getDownloadURL();

    // Step 3: Add a new document to Firestore
    const blogData = {
    title_en: newBlogTitle_en,
    text_en: newBlogTexten,
    desc_title_en: newBlogdesc_en,
    title_es: newBlogTitle_es,
    text_es: newBlogTextes,
    desc_title_es: newBlogdesc_es,
    image_name: `${newBlogTitle_en}_${newBlogImage.name}`,
    image_url: downloadURL
    };

    await db.collection('brainblogs').add(blogData);

    console.log('Blog added successfully:', blogData);
  } catch (error) {
    console.error('Error adding Blog:', error);
  }

  getBlogs();
  setNewBlogTitle_en("");
  setNewBlogTexten("");
  setNewBlogdesc_en("")
  setNewBlogTitle_es("");
  setNewBlogTextes("");
  setNewBlogdesc_es("")
  closeAddModal();
};
  

  return (
    <div className="container mx-auto p-4 relative">
      <h1 className="text-2xl font-bold mb-4">Brain Blogs</h1>
      {/* Button to open Add Testimonial Modal */}
      <div className="absolute top-4 right-4">
        <button onClick={openAddModal} className="bg-green-500 text-white py-2 px-4 rounded">
          Add Blog
        </button>
      </div>
      {/* Display Testimonials */}
      {blogs && (
        <div className="flex flex-col gap-5">
          {blogs.map((blog) => (
            <div key={blog.id} className="bg-white p-4 rounded shadow">
                <img src={blog.image_url} alt={blog.title} className="w-1/4 object-fill mb-2 rounded" />
                <div className='flex w-full gap-4'>
                  <div className='flex-1'>
                    <p>English</p>
                    <p className="text-lg font-semibold">Title: {blog.title_en}</p>
                    <p className="text-lg">Desc Title: {blog.desc_title_en}</p>
                    <p className="text-lg">Content: {blog.text_en}</p>
                  </div>
                  <div className='flex-1'>
                    <p>Spanish</p>
                    <p className="text-lg font-semibold">Title: {blog.title_es}</p>
                    <p className="text-lg">Desc Title: {blog.desc_title_es}</p>
                    <p className="text-lg">Content: {blog.text_es}</p>
                  </div>
                </div>
                <div className="flex justify-end mt-2">
                <button onClick={() => openUpdateModal(blog)} className="mr-2 text-blue-500">
                    Update
                </button>
                <button onClick={() => handleDeleteBlog(blog)} className="text-red-500">
                    Delete
                </button>
                </div>
            </div>
            ))}
        </div>
      )}

    {showAddModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow w-10/12">
            <h2 className="text-2xl font-bold mb-4">Add Testimonial</h2>
            <div className='flex gap-4'>
              <div className='flex-1'>
              <label className="block mb-2">Title (English)</label>
            <input
              type="text"
              value={newBlogTitle_en}
              onChange={(e) => setNewBlogTitle_en(e.target.value)}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Desc Title (English)</label>
            <input
              type="text"
              value={newBlogdesc_en}
              onChange={(e) => setNewBlogdesc_en(e.target.value)}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Content (English)</label>
            <textarea type="text"
              value={newBlogTexten}
              onChange={(e) => setNewBlogTexten(e.target.value)}
              className="w-full p-2 border rounded mb-4"></textarea>
              </div>

              <div className='flex-1'>
              <label className="block mb-2">Title (Spanish)</label>
            <input
              type="text"
              value={newBlogTitle_es}
              onChange={(e) => setNewBlogTitle_es(e.target.value)}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Desc Title (Spanish)</label>
            <input
              type="text"
              value={newBlogdesc_es}
              onChange={(e) => setNewBlogdesc_es(e.target.value)}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Content (Spanish)</label>
            <textarea type="text"
              value={newBlogTextes}
              onChange={(e) => setNewBlogTextes(e.target.value)}
              className="w-full p-2 border rounded mb-4"></textarea>
              </div>

            </div>
            
            <label className="block mb-2">Image:</label>
            <input
              type="file"
              onChange={(e) => setNewBlogImage(e.target.files[0])}
              className="w-full p-2 border rounded mb-4"
            />
            {imageUploadProgress > 0 && (
              <progress value={imageUploadProgress} max="100" className="w-full mb-4"></progress>
            )}
            <button onClick={handleAddBlog} className="mx-5 bg-blue-500 text-white py-2 px-4 rounded">
              Add Blog
            </button>
            <button onClick={closeAddModal} className="mt-4 bg-gray-500 text-white py-2 px-4 rounded">
              Close
            </button>
          </div>
        </div>
      )}


    {/* Update Blog Modal */}
    {showUpdateModal && selectedBlog && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow w-2/3">
            <h2 className="text-2xl font-bold mb-4">Update Blog</h2>
           
            <div className='flex gap-4'>
              <div className='flex-1'>
              <label className="block mb-2">Title (English)</label>
            <input
              type="text"
              value={selectedBlog.title_en}
              onChange={(e) => setSelectedBlog({ ...selectedBlog, title_en: e.target.value })}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Desc Title (English)</label>
            <input
              type="text"
              value={selectedBlog.desc_title_en}
              onChange={(e) => setSelectedBlog({ ...selectedBlog, desc_title_en: e.target.value })}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Content (English)</label>
            <textarea type="text"
              value={selectedBlog.text_en}
              onChange={(e) => setSelectedBlog({ ...selectedBlog, text_en: e.target.value })}
              className="w-full p-2 border rounded mb-4"></textarea>
              </div>

              <div className='flex-1'>
              <label className="block mb-2">Title (Spanish)</label>
            <input
              type="text"
              value={selectedBlog.title_es}
              onChange={(e) => setSelectedBlog({ ...selectedBlog, title_es: e.target.value })}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Desc Title (Spanish)</label>
            <input
              type="text"
              value={selectedBlog.desc_title_es}
              onChange={(e) => setSelectedBlog({ ...selectedBlog, desc_title_es: e.target.value })}
              className="w-full p-2 border rounded mb-4"
            />
            <label className="block mb-2">Content (Spanish)</label>
            <textarea type="text"
              value={selectedBlog.text_es}
              onChange={(e) => setSelectedBlog({ ...selectedBlog, text_es: e.target.value })}
              className="w-full p-2 border rounded mb-4"></textarea>
              </div>

            </div>
            
            <label className="block mb-2">Image:</label>
            <input
              type="file"
              onChange={(e) => handleFileChange(e, 'image_name')}
              className="w-full p-2 border rounded mb-4"
            />
            {imageUploadProgress > 0 && (
              <progress value={imageUploadProgress} max="100" className="w-full mb-4"></progress>
            )}

            {/* Button to trigger the update */}
            <button onClick={() => handleUpdateBlog()} className="mx-2 bg-blue-500 text-white py-2 px-4 rounded">
              Update Blog
            </button>
            <button onClick={closeUpdateModal} className="mt-4 bg-gray-500 text-white py-2 px-4 rounded">
              Close
            </button>
          </div>
        </div>
      )}

    </div>
  );
};

export default BrainBlogs;
