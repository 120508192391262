import Brain_Hero from "./Brain_Hero";
import Brain_inclusions from "./Brain_inclusions";
import Brain_title from "./Brain_title";
import Brain_title_list from "./Brain_title_list";
import Brain_videos from "./Brain_videos";

export default function BrainNews() {

  return (
    <div className="brain-news-container w-full overflow-hidden pt-32">

      <Brain_Hero />

      <div className="title-section flex flex-col justify-center items-center w-full">
        <Brain_title />
        <Brain_title_list/>

        <div className="w-full relative">
          <Brain_inclusions/>
        </div>
        
        <Brain_videos />
      </div>
    </div>
  )
}
