import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { db } from '../../FirebaseConfig';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay'; // Add this line for Autoplay styles

import { motion, useAnimation } from "framer-motion";
import { Autoplay } from 'swiper/modules';
import { useInView } from 'react-intersection-observer';
// Import Swiper styles
import 'swiper/css';
import { useTranslation } from 'react-i18next';
// Install Swiper modules

const Home_partners = () => {
  const {t} = useTranslation();
  var T = t("Index.partners");
  const [ref, inView] = useInView({ triggerOnce: true });
  const [partners, setpartners] = useState([]);
  const [key, setKey] = useState(null);
  const controls = useAnimation();
  
  const getpartners = async () => {
    try{
      const collectionRef = db.collection("partners");
      const snapshot = await collectionRef.get();
    
      const documents = [];
      snapshot.forEach(doc => {
        documents.push({ id: doc.id, ...doc.data() });
      });
    
      setpartners(documents)
    } catch (error) {
      console.error('Error fetching partners:', error);
    }
  };

  useEffect(() => {
    if (partners.length > 0) {
      // This will cause Swiper to reinitialize
      setKey(prevKey => prevKey + 1);
    }
  }, [partners]);

  useEffect(() => {
    getpartners();
  },[]);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  },[controls,inView]);

  const variants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };


return (
<motion.div 
ref={ref}
initial="hidden"
animate={controls}
variants={variants} className="partners mt-16">
      <div className='flex flex-col justify-center items-center relative'>
        <h1 className=''>{T}</h1>
        <Swiper
        key={key}
          spaceBetween={80}
          loop={true}
          slidesPerView={2}
          modules={[Autoplay]}
          autoplay={true}
          breakpoints={{
            600: {
              slidesPerView: 2,
            },
            1024:{
              slidesPerView: 5
            }
          }}
          className="mySwiper w-11/12 overflow-hidden"
        >{partners && partners.map((partner, index) => (
          <SwiperSlide key={index}>
         <div className="partner">
              <img className="h-full" src={partner.image_url} alt='' draggable="false"/>
          </div>
          </SwiperSlide>
        ))}
        </Swiper>
      </div>
    </motion.div>  
  )
}

export default Home_partners;
