import Contact_Title from "./Contact_Title";
import Contact_newsLetter from "./Contact_newsLetter";
import Contact_sendEmail from "./Contact_sendEmail";

export default function ContactUs() {
  return (
    <div className="contactUs_container h-full">
      {/* Title section */}
      <Contact_Title/>

      {/* Send-email section */}
      <Contact_sendEmail/>

      {/* News Letter Section */}
      <Contact_newsLetter/>

      
        <div className="w-full flex gap-10 p-10 justify-center items-center contact_locations">
          <div className="w-full h-full rounded-xl shadow-md relative">
            <div className="loc_overlay mx rounded-xl flex justify-center items-center">
              <h1 className="text-white text-9xl font-medium">MX</h1>
            </div>
            <iframe className="rounded-xl h-full w-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.991717322125!2d-99.17046472438048!3d19.41276384131335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff40a0efc1e3%3A0x980025f771218013!2sAv%20M%C3%A9xico%20175-departamento%20101%2C%20Hip%C3%B3dromo%2C%20Cuauht%C3%A9moc%2C%2006100%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX%2C%20Mexico!5e0!3m2!1sen!2sie!4v1704955317135!5m2!1sen!2sie" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div className="w-full h-full rounded-xl shadow-md relative">
            <div className="loc_overlay uk rounded-xl flex justify-center items-center">
              <h1 className="text-white text-9xl font-medium">UK</h1>
            </div>
            <iframe className="rounded-xl h-full w-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.5189318448283!2d-0.059928923281926984!3d51.540382808293174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ce6120d51e1%3A0x2edbe43ac0d1d5e3!2s1%20Mentmore%20Terrace%2C%20London%20E8%203PN%2C%20UK!5e0!3m2!1sen!2sie!4v1702592844679!5m2!1sen!2sie" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div> 
      
    </div>
  )
}
