import React, { useEffect } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { FaTelegramPlane } from 'react-icons/fa'
import { useTranslation } from 'react-i18next';
const Contact_newsLetter = () => {
  const {t} = useTranslation();
  var text = [t("ContactUs.subscribe-title"), t("ContactUs.subscribe-desc"), t("ContactUs.coming-soon")];
const [ref, inView] = useInView({ triggerOnce: true });
const controllogo = useAnimation();  

useEffect(() => {
    if (inView) {
        controllogo.start({
            y:0,
            opacity:1,
            scale: 1,
            transition:{delay:0.6, duration:0.8, type:"spring"}
        });
    }
}, [controllogo,inView]);

  return (
    // <div className=" overflow-hidden">
      <motion.div 
    ref={ref}
    initial={{ y:200, scale:0.6, opacity:0 }}
    animate={controllogo}
    className="rounded-3xl relative shadow-md news-letter-container p-8 gap-4 m-40 mb-20 mt-5 blue-bg text-white flex flex-col items-center">
        <h3 className="text-center">{text[0]}</h3>
        <form action="" className="rounded-md bg-white w-8/12 flex justify-space-between items-center gap-5">
          <input type="text" name="fname" placeholder={text[1]} required/>
          <button className="" type="submit"><FaTelegramPlane size={30}/></button>
        </form>
        <div className='contactus-overlay'>
          <h2>{text[2]}</h2>
        </div>
      </motion.div>
    // </div>
  )
}

export default Contact_newsLetter
