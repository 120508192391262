import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import i18next from "i18next";
import global_en from "./components/locales/en/global.json"
import global_es from "./components/locales/es/global.json"
import { I18nextProvider } from "react-i18next";

import "./index.css";
import "./components/Navbar/Navbar.css";
import "./components/Footer/Footer.css";
import "./components/HomePage/HomePage.css";
import "./components/Panel/Panel.css";
import "./components/Services/Services.css";
import "./components/ContactUs/ContactUs.css";
import "./components/BrainNews/BrainNews.css";
import "./components/AboutsUs/AboutUs.css";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en", // default language
  resources: {
    en: {
      translation: global_en
    },
    es: {
      translation: global_es
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  // <React.StrictMode>
    <BrowserRouter basename="/">
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
    </BrowserRouter>    
  // </React.StrictMode>
);