import React, { useEffect } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

const Service_methods = () => {
const {t} = useTranslation();
const [ref, inView] = useInView({ triggerOnce: true });
const [ref1, inView1] = useInView({ triggerOnce: true });
const controlheading = useAnimation();  
const method1 = useAnimation();  
const method2 = useAnimation();  
const method3 = useAnimation();  
const method4 = useAnimation();  

useEffect(() => {
  if (inView || inView1) {
    controlheading.start({
          scale:1,
          y:0,
          opacity:1,
          transition:{delay:0.4, duration:0.8, type:"spring"}
      });
      method1.start({
        y:0,
        opacity:1,
        transition:{delay:0.8, duration:1, type:"spring"}
    });  
    method2.start({
      y:0,
      opacity:1,
      transition:{delay:1.2, duration:1, type:"spring"}
  }); 
  method3.start({
    y:0,
    opacity:1,
    transition:{delay:1.6, duration:1, type:"spring"}
}); 
method4.start({
  y:0,
  opacity:1,
  transition:{delay:2, duration:1, type:"spring"}
}); 
  }
}, [controlheading, method1, method2, method3, method4,inView, inView1]);

  return (
    <div className='methods-container'>
      <motion.h1
      ref={ref1}
      initial={{ opacity:0, scale:0.7, y:150 }}
      animate={controlheading}
      >{t("Service_Methods.title")}</motion.h1>
      <div className='flex gap-16 p-20 methods justify-end items-end'>
      <motion.div 
      ref={ref}
      initial={{ opacity:0, y:200 }}
      animate={method1}
      className="h-3/6 method">
        <div className='number-div pink-bg'>
          <h2 className='pink-bg text-white'>1<sup>st</sup></h2>
        </div>
        <div className="icon-container">
          <img src='/svgs/service-method1.svg'/>
        </div>
        <div className="method-desc pink-bg text-white">
          <h2>{t("Service_Methods.method-1")}</h2>
          <p>{t("Service_Methods.method-1-desc")}</p>
        </div>
      </motion.div>

      <motion.div 
      ref={ref}
      initial={{ opacity:0, y:200 }}
      animate={method2} className="h-3/5 method">
        <div className='number-div blue-bg'>
          <h2 className='blue-bg text-white'>2<sup>nd</sup></h2>
        </div>
        <div className="icon-container">
          <img src='/svgs/service-method2.svg'/>
        </div>
        <div className="method-desc blue-bg text-white">
        <h2>{t("Service_Methods.method-2")}</h2>
          <p>{t("Service_Methods.method-2-desc")}</p>
        </div>
      </motion.div >


      <motion.div 
      ref={ref}
      initial={{ opacity:0, y:200 }}
      animate={method3} className=" h-4/5 method">
        <div className="icon-container">
          <img src='/svgs/service-method3.svg'/>
        </div>
        <   div className='number-div green-bg'>
          <h2 className='green-bg text-white'>3<sup>rd</sup></h2>
        </div>
        <div className="method-desc green-bg text-white">
        <h2>{t("Service_Methods.method-3")}</h2>
          <p>{t("Service_Methods.method-3-desc")}</p>
        </div>
      </motion.div >
      
      <motion.div 
      ref={ref}
      initial={{ opacity:0, y:200 }}
      animate={method4} className=" h-85 method">
        <div className='number-div orange-bg'>
          <h2 className='orange-bg text-white'>4<sup>th</sup></h2>
        </div>
        <div className="icon-container">
          <img src='/svgs/service-method4.svg'/>
        </div>
        <div className="method-desc orange-bg text-white">
        <h2>{t("Service_Methods.method-4")}</h2>
          <p>{t("Service_Methods.method-4-desc")}</p>
        </div>
      </motion.div >
    </div>
    </div>
  )
}

export default Service_methods
