import React from 'react'
import { PopupButton } from '@typeform/embed-react'
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';

const TypeformComp = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  function checkLang(){
    if (currentLanguage === "en"){
      return true
    }
    return false
  }

    return (
        <motion.div 
        initial={{ y:100, opacity:0 }}
        animate={{ y:0, opacity:1, transition:{delay: 0.5, duration:1, type:"spring"} }}
        className='w-fit'>
          <div className='rounded-full shadow-xl carbon-cal'>
          <PopupButton id={checkLang() ? "fMv4TStj" : "E5RA2NOv"} style={{ fontSize: 20 }} className="my-button flex flex-col justify-center items-center">
           
          {checkLang() ? (<img className=" w-full" src="/images/carbon_button_en.png" alt="1" />)  : (<img className=" w-full" src="/images/carbon_button_es.png" alt="1" />) }
          </PopupButton>
        </div>
        </motion.div>


        
      )
}

export default TypeformComp
