import React, { useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

const Home_location = () => {
  const {t} = useTranslation();
  var translation = [t("Index.london"), t("Index.mexico")] ;

  const [ref, inView] = useInView({ triggerOnce: true });
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  useEffect(() => {
    if (inView) {
      controls1.start({ x:0, opacity:1, transition:{ delay: 0.5, duration:1 }});
      controls2.start({ x:0, opacity:1, transition:{ delay: 0.5, duration:0.4 }});
    }},[inView, controls1]);

return (
  <div 
  className="w-full location-container mb-6 mt-16">
        <motion.div  
        ref={ref}
        initial={{ x:-250, opacity:0 }}
        animate={controls1}
        className="image-container-uk">
          <img src="./images/home-page-uk.jpg" alt="" />
          <h1 className="uk-heading">UK</h1>
          <div className="location-marker">
              <div className="icon-container">
                <img src="./images/location-pin.png" alt="" />
              </div>
              <p className="city-name">{translation[0]}</p>
          </div>
        </motion.div >
        <motion.div  
        ref={ref}
        initial={{ x:0, opacity:1 }}
        animate={controls2}
        className="image-container-mx">
            <img src="./images/home-page-mx.jpg" alt="" />
            <h1 className="mx-heading">MX</h1>
            <div className="location-marker">
              <div className="icon-container">
                <img src="./images/location-pin.png" alt="" />
              </div>
              <p className="city-name">{translation[1]}</p>
          </div>
        </motion.div >
    </div>
  )
}

export default Home_location
