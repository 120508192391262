import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from 'react';

const Hero_style = () => {
    const bigPink = useAnimation();
    const smallBlue = useAnimation();
    const smallGreen = useAnimation();
    const halfBlue = useAnimation();
    const halfGreen = useAnimation();
    const halfOrange = useAnimation();
    const sqaurePurple = useAnimation();
    const halfPurple = useAnimation();
    const top1 = useAnimation();
    const bottom1 = useAnimation();
    const middle1 = useAnimation();
    const bottom2 = useAnimation();

    useEffect(() => {
        bigPink.start({
            opacity: 1,
            x: 0,
            y: 0,
            scale: 1,
            transition: { duration: 1, type:"just" },
        });
        smallBlue.start({
            opacity: 1,
            x: 0,
            y: 0,
            scale: 1,
            rotate: 0,
            transformOrigin: "bottom",
            transition: { duration: 1, delay: 0.8, type:"spring" },
        });
        smallGreen.start({
            opacity: 1,
            x: 0,
            y: 0,
            scale: 1,
            rotate: 0,
            transformOrigin: "bottom",
            transition: { duration: 1, delay: 2.4},
        });
        halfBlue.start({
            opacity: 1,
            x: 0,
            y: 0,
            scale: 1,
            transformOrigin: "bottom",
            transition: { duration: 1, delay: 1.4},
        });
        halfGreen.start({
            opacity: 1,
            x: 0,
            y: 0,
            scale: 1,
            transformOrigin: "bottom",
            transition: { duration: 1, delay: 2.4},
        });
        halfOrange.start({
            opacity: 1,
            x: 0,
            y: 0,
            scale: 1,
            transformOrigin: "bottom",
            transition: { duration: 1, delay: 1.4, type:"just"},
        });
        sqaurePurple.start({
            opacity: 1,
            x: 0,
            y: 0,
            scale: 1,
            transformOrigin: "bottom",
            transition: { duration: 1, delay: 1.5},
        });
        halfPurple.start({
            opacity: 1,
            x: 0,
            y: 0,
            scale: 1,
            transformOrigin: "bottom",
            transition: { duration: 1, delay: 1.5},
        });
        top1.start({
            opacity: 1,
            transformOrigin: "bottom",
            transition: { duration: 1, delay: 1.7},
        });
        bottom1.start({
            opacity: 1,
            transformOrigin: "bottom",
            transition: { duration: 1, delay: 2.1},
        });
        middle1.start({
            opacity: 1,
            transformOrigin: "bottom",
            transition: { duration: 1, delay: 1.5},
        });
        bottom2.start({
            opacity: 1,
            transformOrigin: "bottom",
            transition: { duration: 1, delay: 2.1},
        });
    }, [bigPink, smallBlue, smallGreen, halfBlue, halfGreen, 
        halfOrange, sqaurePurple, halfPurple, top1, bottom1, middle1, bottom2]);

    return (
        <div className="h-full w-full overflow-hidden relative">
            {/* Big Pink Capsule */}
            <svg id="Layer_1" data-name="Layer 1" className="h-full absolute right-0 top-0"viewBox="0 0 593.93 814.53">
            <defs>
                <clipPath id="clippath">
                <rect width="593.93" height="814.53" fill="none" strokeWidth="0"/>
                </clipPath>
            </defs>
            <g clipPath="url(#clippath)">
                <g>
                <motion.path 
                initial={{ opacity: 0, x: -50, y: -50, scale: 1}}
                animate={halfOrange}
                d="M392,513.99l113.73,115.65c39.26,39.92,39.09,104.48-.38,144.19h0c-39.47,39.71-103.29,39.54-142.55-.38l-112.98-114.88" fill="#e94f1c" strokeWidth="0"/>
                
                <motion.path 
                initial={{ opacity: 0, x: 60, y: 60, scale: 1}}
                animate={halfBlue}
                d="M286.85,497.83l-113.73-115.65c-39.26-39.92-39.09-104.48.38-144.19h0c39.47-39.71,103.29-39.54,142.55.38l112.98,114.88" fill="#6090b2" strokeWidth="0"/>
                
                <motion.path 
                initial={{ opacity: 0, x: 250, y: -300, scale:1 }}
                animate={bigPink}
                d="M181.48,590.27h0c-40.77-41.02-40.94-107.71-.39-148.95L574.08,41.7c40.55-41.24,106.48-41.42,147.25-.39h0c40.77,41.02,40.94,107.71.39,148.95l-392.99,399.63c-40.55,41.24-106.48,41.42-147.25.39Z" fill="#e50e7e" strokeWidth="0"/>
                
                <motion.path 
                initial={{ opacity: 0, x:0, y: -210, scale: 0.5, rotate: -90 }}
                animate={smallBlue}
                d="M114.64,768.41h0c-30.7-30.89-30.83-81.11-.29-112.16l151.31-153.86c30.54-31.05,80.18-31.19,110.89-.3h0c30.7,30.89,30.83,81.11.29,112.16l-151.31,153.86c-30.54,31.05-80.18,31.19-110.89.3Z" fill="#6090b2" strokeWidth="0"/>    


                <motion.path 
                initial={{ opacity: 0, x: -50, y: -50, scale:1}}
                animate={halfGreen}
                d="M479.96,436.1l56.32,57.27c20.19,20.54,20.11,53.74-.19,74.17h0c-20.3,20.43-53.13,20.34-73.33-.2l-55.94-56.88" fill="#3eab36" strokeWidth="0"/>
                
                <motion.path 
                initial={{ opacity: 0, x: 50, y: 50, scale:1}}
                animate={halfPurple}
                d="M406.82,510.46l-58.5-59.49c-20.19-20.54-20.11-53.74.19-74.17h0c20.3-20.43,53.13-20.34,73.33.2l58.12,59.1" fill="#652682" strokeWidth="0"/>
                
                <motion.path 
                initial={{ opacity: 0, x: 25, y: 25, scale:0.7}}
                animate={smallGreen} 
                d="M242.4,612.48L39.25,405.22c-20.19-20.54-20.11-53.74.19-74.17h0c20.3-20.43,53.13-20.34,73.33.2l202.76,206.86" fill="#7bba26" strokeWidth="0"/>
                
                <motion.path 
                initial={{ opacity: 0, x: 0, y: 0, scale:0.5}}
                animate={sqaurePurple}  
                d="M376.54,502.09h0c-30.7-30.89-80.35-30.76-110.89.3l-85.29,86.73c.37.38.74.77,1.11,1.15h0c40.77,41.02,106.7,40.85,147.25-.39l63.72-64.79c-3.8-8.33-9.1-16.14-15.91-22.99Z" fill="#652682" strokeWidth="0"/>
                
                <motion.image 
                initial={{ opacity: 0}}
                animate={bottom2}
                xlinkHref={`/homePage-figure/bottom2.png`} x="18%" y="80%" width="121" height="121" />     
                <motion.image 
                initial={{ opacity: 0}}
                animate={top1}
                xlinkHref={`/homePage-figure/top1.png`} x="27%" y="27.5%" width="171" height="171" />       
                <motion.image 
                initial={{ opacity: 0}}
                animate={middle1}
                xlinkHref={`/homePage-figure/middle1.png`} x="28%" y="52%" width="185" height="185" />  
                <motion.image 
                initial={{ opacity: 0}}
                animate={bottom1}
                xlinkHref={`/homePage-figure/bottom1.png`} x="58%" y="75%" width="180" height="180" /> 
                </g>
            </g>
            </svg>
        </div>
    );
};

export default Hero_style;
