import Service_Results from "./Service_Results";
import Service_methods from "./Service_Methods";
import Service_testimonial from "./Service_Testimonial";
import Service_desc from "./Service_desc"
import Service_title from "./Service_title"
import { useTranslation } from 'react-i18next';

export default function Habit() {

  const {t} = useTranslation()
  var DescTranslations = [t("Habit.colored-desc"), t("Habit.service-objective"), t("Habit.service-objective-desc-1"), t("Habit.service-objective-desc-2")]
  var resultsTranslations = [t("Habit.result-1"),t("Habit.result-2"),t("Habit.result-3"),
                             t("Habit.result-4"),t("Habit.result-5") ,t("Habit.result-6")]


  return (
    <div className="emotional-service-container">
      
      {/* Service Title section */}
      <Service_title service_type={"Habit"}/>

      {/* Service desc section */}
      <Service_desc service_type={"Habit"} translations={DescTranslations}/>

      {/* Results Section */}
      <Service_Results service_type={"Habit"} translations={resultsTranslations}/>

      {/* Methods Section */}
      <Service_methods/>

      {/* Testimonial Section */}
      <Service_testimonial service_type={"Habit"}/>

    </div>
  )
}

