import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay } from 'swiper/modules';
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import { db } from '../../FirebaseConfig';

const Brain_videos = () => {

    const ref = useRef();
    const [swiper, setSwiper] = useState(null);
    const [videos, setVideos] = useState([]);
    const onSwiper = (swiperInstance) => {
        setSwiper(swiperInstance);
    };

    const [ref1, inView] = useInView({ triggerOnce: true });
    const controllogo = useAnimation();  

    useEffect(() => {
        if (inView) {
            controllogo.start({
                y:0,
                opacity:1,
                transition:{delay:0.6, duration:0.8, type:"spring"}
            });
        }
    }, [controllogo, inView]);

    const getVideos = async () => {
        try{
          const collectionRef = db.collection("brainVideos");
          const snapshot = await collectionRef.get();
        
          const documents = [];
          snapshot.forEach(doc => {
            documents.push({ id: doc.id, ...doc.data() });
          });
        
          setVideos(documents)
        } catch (error) {
          console.error('Error fetching Brain Videos:', error);
        }
        };
  
      useEffect(() => {
        getVideos();
      },[]);

  return (
    <motion.div
    ref={ref1}
    initial={{opacity:0, y:-150}}
    animate={controllogo}
    className='brain-vids-container relative w-full my-10 p-36'>
      <Swiper
                spaceBetween={40}
                ref={ref}
                modules={[Autoplay]}
                breakpoints={{
                    600: {
                        slidesPerView: '1',
                    },
                    800:{
                        slidesPerView:'3'
                    }
                }
                }
                autoplay={{ delay: 3000 }}
                loop={true}
                className="mySwiper mx-10"
                onSwiper={onSwiper} // Callback to get the swiper instance
            >
                {videos && videos.map((video, index) => (
                <SwiperSlide key={index}>
                    <div className='brainnews_podcast'>
                    <iframe className="shadow-md rounded-xl video" src={video.videoLink} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                </SwiperSlide>
                ))}
      </Swiper>   
        
        {swiper && (
            <div className='flex justify-center z-50 mt-2'>
                <button className="slidePrevBtn" onClick={() => swiper.slidePrev()}><GrPrevious size={43} /></button>
                <button className="slideNextBtn" onClick={() => swiper.slideNext()}><GrNext size={43} /></button>
                
            </div>
        )}
    </motion.div>
  )
}

export default Brain_videos
