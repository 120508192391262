import React, { useEffect, useState } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { db } from '../../FirebaseConfig';

import { FaWhatsapp, FaFacebookF, FaInstagram, FaLinkedin, FaYoutube, FaSpotify, FaMapMarkerAlt } from "react-icons/fa";
import TypeformComp from '../HomePage/TypeformComp';

const Footer = () => {

  const {t} = useTranslation();
  var till = {
    "social-media-text": t("Footer.social-media-text"),
    "contact_us": t("Footer.contact_us"),
    "address1": t("Footer.address1"),
    "address2": t("Footer.address2"),
    "rights": t("Footer.rights"),
    "privacy": t("Footer.privacy"),
    "WhatsApp": t("Footer.link-WhatsApp"),
    "Facebook": t("Footer.link-Facebook"),
    "Instagram": t("Footer.link-Instagram"),
    "Spotify": t("Footer.link-Spotify"),
    "Youtube": t("Footer.link-Youtube"),
    "LinkedIn": t("Footer.link-LinkedIn")
  };
  const [ref, inView] = useInView({ triggerOnce: true });
  const controllogo = useAnimation();
  const controlmeidaheading = useAnimation()
  const media1 = useAnimation();
  const media2 = useAnimation();
  const media3 = useAnimation();
  const media4 = useAnimation();
  const media5 = useAnimation();
  const media6 = useAnimation();
  const pinkDiv = useAnimation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [links, setLinks] = useState([]);  

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (inView) {
      pinkDiv.start({
        opacity:1,
        x:0,
        transition:{delay:0.7, duration:0.7}
      });
      
      controllogo.start({
        y:0,
        opacity:1,
        rotate:0,
        scale:1,
        transition: {delay:0.4, duration:1}
      });
      controlmeidaheading.start({
        y:0,
        opacity:1,
        transition: {delay:0.6, duration:1}
      });
      media1.start({
        opacity:1,
        x:0,
        transition:{delay: 0.5, duration: 0.5, type:"just"}
      });
      media2.start({
        opacity:1,
        x:0,
        transition:{delay: 1.1, duration: 0.5, type:"just"}
      });
      media3.start({
        opacity:1,
        x:0,
        transition:{delay: 1.7, duration: 0.5, type:"just"}
      });
      media4.start({
        opacity:1,
        x:0,
        transition:{delay: 2.3, duration: 0.5, type:"just"}
      });
      media5.start({
        opacity:1,
        x:0,
        transition:{delay: 2.9, duration: 0.5, type:"just"}
      });
      media6.start({
        opacity:1,
        x:0,
        transition:{delay: 3.5, duration: 0.5, type:"just"}
      });
    }
  },[controllogo,controlmeidaheading,pinkDiv,media1,media2,media3,media4,media5,media6,inView]);

  const getlinks = async () => {
    try{
      const collectionRef = db.collection("sociallinks");
      const snapshot = await collectionRef.get();
    
      const documents = [];
      snapshot.forEach(doc => {
        documents.push({ id: doc.id, ...doc.data() });
      });
    
      setLinks(documents)
    } catch (error) {
      console.error('Error fetching Social Links:', error);
    }
  };

  useEffect(() => {
    getlinks();
  },[]);
  return (
    <div className="footer w-full justify-center items-center flex flex-col overflow-hidden">
        <div className="contacts-container flex w-full gap-4 justify-center items-center">
            <motion.div 
            ref={ref}
            initial={{ y:-150, opacity:0, scale:0.2, rotate:180 }}
            animate={controllogo}
            className="logo-container p-8 mx-14">
              <img className="w-full" src="/images/logo.png" alt="" />
            </motion.div>
            <div className="social-media-container justify-center items-center flex flex-col mb-10">
            <div className='w-full flex justify-center items-center'>
              <div className="w-fit my-bounce-animation">
              <TypeformComp />
              </div>
            </div>
              <motion.p 
              ref={ref}
              initial={{ opacity:0, y:-100 }}
              animate={controlmeidaheading}
              className="social-media-text text-center p-5">{till["social-media-text"]}</motion.p>
              <div className="links-container flex gap-4 w-full justify-center items-center">
                <motion.a 
                ref={ref}
                initial={{opacity:0, x:-70}}
                animate={media1}
                href={(links[4]) ? links[4].url : "" } id="whatsapp_link" className='social-media-links  w-14 h-14 flex justify-center items-center'><FaWhatsapp size={21}/></motion.a>
                <motion.a 
                ref={ref}
                initial={{opacity:0, x:-70}}
                animate={media2} 
                href={(links[0]) ? links[0].url : "" } id="facebook_link" className='social-media-links w-14 h-14 flex justify-center items-center'><FaFacebookF size={21}/></motion.a>
                <motion.a 
                ref={ref}
                initial={{opacity:0, x:-70}}
                animate={media3} 
                href={(links[1]) ? links[1].url : "" } id="instagram_link" className='social-media-links w-14 h-14 flex justify-center items-center'><FaInstagram  size={21}/></motion.a>
                <motion.a 
                ref={ref}
                initial={{opacity:0, x:-70}}
                animate={media4} 
                href={(links[2]) ? links[2].url : "" } id="linkedin_link" className='social-media-links w-14 h-14 flex justify-center items-center'><FaLinkedin  size={21}/></motion.a>
                <motion.a 
                ref={ref}
                initial={{opacity:0, x:-70}}
                animate={media5} 
                href={(links[5]) ? links[5].url : "" } id="youtube_link" className='social-media-links w-14 h-14 flex justify-center items-center'><FaYoutube  size={21}/></motion.a>
                <motion.a 
                ref={ref}
                initial={{opacity:0, x:-70}}
                animate={media6} 
                href={(links[3]) ? links[3].url : "" } id="spotify_link" className='social-media-links w-14 h-14 flex justify-center items-center'><FaSpotify  size={21}/></motion.a>
              </div>
            </div>
            <motion.div 
            ref={ref}
            initial={{ opacity:0, x:200 }}
            animate={pinkDiv}
            className="address-container">
              <h2 className="text-3xl font-semibold">{till.contact_us}</h2>
              <p className='email'>hola@braintonic.org</p>
              <div className="flex gap-2 items-center">
              <FaMapMarkerAlt size={21} color='white' className="inline"/>
              <p className="text-base"> 
                {till.address1}
              </p>
              </div>
              <div className="flex gap-2 items-center">
              <FaMapMarkerAlt size={21} color='white' className="inline"/>
              <p className="text-base"> 
                {till.address2}
              </p>
              </div>
            </motion.div>
        </div>
        <div className="rights-container flex w-full justify-evenly items-center gap-40">
            <p className="text-lg">&copy; <a href="/panel">2024</a> Braintonic. {till.rights}</p>
            <a href='#' onClick={openModal}>{till.privacy}</a>
        </div>

        {isModalOpen && (till.privacy === "Notice of Privacy") &&(
         <div className="fixed z-9999 overflow-y-auto inset-10 flex items-center justify-center bg-white rounded-xl shadow-2xl">
          <div className=" h-full p-8 rounded-xl " >
            <div className="absolute top-5 right-5">
              <button className="text-gray-500 hover:text-gray-700 font-extrabold text-3xl" onClick={closeModal}>X</button>
            </div>
            <div className="mt-5">
            <p className=' font-extrabold'>PRIVACY POLICY FOR THE BRAIN TONIC WEBSITE</p>

              <div className=' overflow-y-auto h-full'>
              
              <p className=' font-extrabold'>ACCEPTANCE OF CONDITIONS</p>
              <p>The use of the website www.braintonic.org implies the complete and
unconditional acceptance of the terms and conditions set forth in this policy
by the user. We recommend a careful reading of this document, and in
case of any doubts, please contact us through the following means:</p>
              <p>Email: hola@braintonic.org</p>
              <p className=' font-extrabold'>SCOPE OF THE POLICY</p>
              <p>These policies govern the content of the site www.braintonic.org, as well as
the associated links and materials. Any link leading to third-party sites will
be governed by the specific policies of those sites, releasing Rethical Ltd.
and Brain Tonic Servicios, S.A. de C.V., and their related entities, from
liability regarding the content and use of such links and materials.</p>
               <p className=' font-extrabold'>RESPONSIBILITY OF BRAIN TONIC</p>
               <p>Rethical (Brain Tonic) is the entity responsible for the operation of the site.
Brain Tonic is exempt from any liability related to the truthfulness or quality
of the information provided by users on the site. We do not endorse or
approve third-party advice, opinions, or statements, and the responsibility
for decisions based on them lies solely with the users.</p>
<p>Additionally, Brain Tonic does not assume responsibility for links to other
sites or for transactions made by users with third parties. The inclusion of
links on the site does not imply sponsorship, association, or relationship
with those sites or their operators.</p>
                <p className=' font-extrabold'>USER OBLIGATIONS</p>
                <p>The user agrees to use the information on the site solely for personal
purposes and undertakes not to use it for unauthorized commercial or
informational purposes. They will refrain from modifying the information on
the site or using it for abusive or unfair competition practices.</p>

<p>By using the site, the user agrees not to collect, copy, or disseminate
information from third parties without express authorization. They agree not
to create false identities, use false email addresses, or perform acts that
deceive about their identity. Any misuse of the site will be subject to
compensation for damages according to applicable law.</p>

<p className='font-extrabold'>PRIVACY</p>
<p>Rethical Ltd. (Controller), with registered office at 1 Mentmore Terrace, E8
3PN, London, United Kingdom, will process the user&#39;s personal data for
various purposes, such as fulfilling commercial obligations, informing about
events and promotions, conducting satisfaction surveys, and internal
studies for the development of new Brain Tonic products and services. For
more information and to exercise your privacy rights, please consult the
Full Privacy Notice at http://www.braintonic.org/aviso-de-privacidad.
The voluntary and free provision of information by the user constitutes their
express consent to the processing of their personal data under the
conditions of the Brain Tonic Full Privacy Notice, compliant with applicable
privacy laws in the United Kingdom.</p>

<p className='font-extrabold'>INDUSTRIAL AND INTELLECTUAL PROPERTY RIGHTS</p>
The trademarks, images, logos, and other materials related to the products
contained on the site www.braintonic.org are the property of Rethical Ltd.
Copying, reproducing, modifying, or downloading these contents without
prior written authorization from Brain Tonic is prohibited. Any violation of
these rights empowers Brain Tonic to take legal actions, including criminal
actions.
<p>In the event that a user provides information or materials to the site, they
assume full responsibility and release Brain Tonic from any liability related
to third-party rights, especially intellectual property. The user agrees that
Brain Tonic&#39;s use of such materials is free and not subject to any payment
or limitations.</p>
<p>All trademarks, notices, and trade names appearing on this site are the
property of Brain Tonic. In cases where trademarks not owned by Brain
Tonic are used, this will be clearly indicated, without implying sponsorship
or endorsement.</p>
<p className='font-extrabold'>EXCLUSION OF LIABILITY</p>
<p>Brain Tonic reserves the right to establish maintenance periods for the site
without prior notice to the user. The user acknowledges that the use of
websites and online platforms carries risks beyond the control of Brain
Tonic and agrees to implement the necessary security measures to protect the information exchanged with Brain Tonic. Although computer security
measures are implemented, the user acknowledges that data breaches can
occur fraudulently, such as cyberattacks or hacking, despite the computer
security measures that Brain Tonic implements to protect the site.</p>
<p className='font-extrabold'>EXPORT CONTROL</p>
<p>The products transferred to the user may be subject to export controls and
anti-corruption laws in force in the United Kingdom or the Republic of
Mexico. Violation of these laws will result in the cancellation of orders and
commercial relationships.</p>
<p className='font-extrabold'>COMPETITION AND APPLICABLE LAW</p>
<p>The user agrees that any aspect related to the site, its operation, and use
will be interpreted in accordance with the applicable legislation in the
United Kingdom. Therefore, any dispute arising from the use or operation
of the site will be subject to the jurisdiction of the competent courts in the
City of London, complying with the privacy laws in the United Kingdom.</p>
              </div>
            </div>
          </div>
        </div>
      )}

{isModalOpen && (till.privacy === "Aviso de Privacidad") &&(
         <div className="fixed z-9999 overflow-y-auto inset-10 flex bg-white items-center justify-center rounded-xl shadow-2xl">
          <div className="p-8 rounded-xl h-full" >
            <div className="absolute top-5 right-5">
              <button className="text-gray-500 hover:text-gray-700 font-extrabold text-3xl" onClick={closeModal}>X</button>
            </div>
            <div className="mt-5">
            <p className=' font-extrabold'>POLÍTICAS DE PRIVACIDAD DEL SITIO WEB DE BRAIN TONIC</p>

              <div className=' overflow-y-auto h-full'>
              
              <p className=' font-extrabold'>ACEPTACIÓN DE CONDICIONES</p>
              <p>El uso del sitio www.braintonic.org implica la aceptación completa e incondicional de
los términos y condiciones establecidos en esta política por parte del usuario. Se
recomienda la lectura cuidadosa de este documento, y en caso de dudas,
comunicarse con nosotros a través de los siguientes medios:</p>
              <p>Correo electrónico: hola@braintonic.org</p>
              <p className=' font-extrabold'>ALCANCE DE LA POLÍTICA</p>
              <p>Estas políticas rigen el contenido del sitio www.braintonic.org, así como los enlaces
y materiales asociados. Cualquier enlace dirigido a sitios de terceros se regirá por
las políticas específicas de esos sitios, eximiendo a Rethical Ltd. y Brain Tonic
Servicios, S.A. de C.V., y sus entidades relacionadas, de responsabilidad sobre el
contenido y uso de dichos enlaces y materiales.</p>
               <p className=' font-extrabold'>RESPONSABILIDAD DE BRAIN TONIC</p>
               <p>Rethical Ltd (Brain Tonic) es la entidad responsable de la operación del sitio. Se
exime a Brain Tonic de cualquier responsabilidad relacionada con la veracidad o
calidad de la información proporcionada por los usuarios en el sitio. No respaldamos
ni aprobamos consejos, opiniones o declaraciones de terceros, y la responsabilidad
de las decisiones basadas en estos recae únicamente en los usuarios.</p>
<p>Además, Brain Tonic no asume responsabilidad por los enlaces a otros sitios ni por
las transacciones realizadas por los usuarios con terceros. La inclusión de enlaces
en el sitio no implica patrocinio, asociación o relación con esos sitios o sus
operadores.</p>
                <p className=' font-extrabold'>OBLIGACIONES DEL USUARIO</p>
                <p>El usuario acepta utilizar la información del sitio exclusivamente para fines
personales y se compromete a no utilizarla con fines comerciales o informativos no
autorizados. Se abstendrá de modificar la información del sitio o utilizarla para
prácticas abusivas o de competencia desleal.</p>

<p>Al hacer uso del sitio, el usuario se compromete a no recopilar, copiar o diseminar
información de terceros sin autorización expresa. Se compromete a no crear
identidades falsas, utilizar direcciones de correo electrónico falsas o realizar actos
que engañen sobre su identidad. Cualquier uso indebido del sitio será sujeto a
compensación por daños y perjuicios según la legislación aplicable.</p>

<p className='font-extrabold'>PRIVACIDAD</p>
<p>Rethical Ltd. (Responsable), con domicilio en 1 Mentmore Terrace, E8 3PN,
Londres, Reino Unido, tratará los datos personales del usuario para diversas
finalidades, como el cumplimiento de obligaciones comerciales, informar sobre
eventos y promociones, realizar encuestas de satisfacción y estudios internos para
el desarrollo de nuevos productos y servicios Brain Tonic. Para obtener más
información y ejercer sus derechos de privacidad, consulte el Aviso de Privacidad
Completo en http://www.braintonic.org/aviso-de-privacidad.
La manifestación libre y voluntaria de información por parte del usuario constituye su
consentimiento expreso para el tratamiento de sus datos personales según las
condiciones del Aviso de Privacidad Completo de Brain Tonic.</p>

<p className='font-extrabold'>DERECHOS DE PROPIEDAD INDUSTRIAL E INTELECTUAL</p>
<p>Las marcas, imágenes, logotipos y demás materiales relacionados con los
productos contenidos en el sitio www.braintonic.org son propiedad de Rethical Ltd. o
de sus socios o fundadores. Queda prohibida la copia, reproducción, modificación o
descarga de estos contenidos sin autorización previa y por escrito de Brain Tonic.
Cualquier violación de estos derechos faculta a Brain Tonic para ejercer acciones
legales, incluyendo acciones penales.</p>
<p>En caso de que un usuario proporcione información o materiales al sitio, asume la
responsabilidad total y libera a Brain Tonic de cualquier responsabilidad relacionada
con derechos de terceros, especialmente de propiedad intelectual. El usuario acepta
que el uso que Brain Tonic haga de estos materiales es libre y no está sujeto a pago
ni limitaciones.</p>
<p>Todas las marcas, avisos y nombres comerciales presentes en el sitio son propiedad
de Brain Tonic. Cualquier uso de marcas que no sean propiedad de Brain Tonic se
indicará claramente, sin que esto implique un patrocinio o respaldo.</p>
<p className='font-extrabold'>EXCLUSIÓN DE RESPONSABILIDAD</p>
<p>Brain Tonic se reserva el derecho de establecer periodos de mantenimiento del sitio
sin previo aviso al usuario. El usuario reconoce que el uso de sitios web y
plataformas en línea conlleva riesgos fuera del alcance de Brain Tonic, y se
compromete a implementar las medidas de seguridad necesarias para proteger la
información intercambiada con Brain Tonic. Aunque se implementen medidas de
seguridad informática, el usuario reconoce que la vulneración de datos puede ocurrir
de manera fraudulenta, como ataques cibernéticos o de hackers.</p>
<p className='font-extrabold'>CONTROL DE EXPORTACIONES</p>
<p>Los productos transferidos al usuario pueden estar sujetos a controles de
exportación y leyes anticorrupción vigentes en el Reino Unido o la República
Mexicana. La violación de estas leyes resultará en la cancelación de pedidos y
relaciones comerciales.</p>
<p className='font-extrabold'>COMPETENCIA Y LEY APLICABLE</p>
<p>El usuario acepta que cualquier aspecto relacionado con el sitio, su operación y uso
se interpretará de conformidad con la legislación aplicable en el Reino Unido. Por lo
tanto, cualquier controversia derivada del uso o funcionamiento del sitio se someterá
a la competencia de los tribunales competentes en la Ciudad de Londres, sin
importar el domicilio del usuario o el lugar desde donde acceda al sitio.</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Footer
