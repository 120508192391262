import { motion } from "framer-motion";
import React from 'react'
import { useTranslation } from 'react-i18next';

const Hero_text = () => {
  const {t} = useTranslation();
  var text = [t("Index.hero-text-span"), t("Index.hero-text-h2")];
  return (
   
      <motion.div 
      initial={{ opacity: 0, y: 20, x:30 }}
      animate={{ opacity: 1, y: 0, x:0 }}
      transition={{ delay:0.7, duration: 0.5 }} 
      className="hero-text">
       <h2><span>{text[0]}</span> {text[1]}</h2>
     </motion.div>
  )
}

export default Hero_text
    