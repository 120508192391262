import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { getFirestore, collection, getDocs } from "firebase/firestore";


const firebaseConfig = { 
  apiKey : "AIzaSyAsiAUQ4olUXm3auY5ekTQG_Og_DDhnA6s" , 
  authDomain : "braintonicwebsite.firebaseapp.com" , 
  projectId : "braintonicwebsite" , 
  storageBucket : "braintonicwebsite.appspot.com" , 
  messagingSenderId : "72214195873" , 
  appId : "1:72214195873:web:d06ddd089b1ea9f20e4ae0" , 
  measurementId : "G-D4MX4GB75P" 
};

if(!firebase.apps.length){
    firebase.initializeApp(firebaseConfig);
}
const db = firebase.firestore();
const storage = firebase.storage();
export { firebase, getFirestore, collection, getDocs, db, storage };
