import { useTranslation } from 'react-i18next';
import Corporate_title from "./Corporate_title";
import Corporate_rethical from "./Corporate_rethical";
import Service_Results from "./Service_Results";
import Service_methods from "./Service_Methods";
import Service_testimonial from "./Service_Testimonial";

export default function Corporate() {

  const {t} = useTranslation();
  var resultsTranslations = [t("Corporate.result-1"), t("Corporate.result-2"), 
                             t("Corporate.result-3"), t("Corporate.result-4"), 
                             t("Corporate.result-5"), t("Corporate.result-6"), t("Corporate.result-7")];
 var titleTranslations = [t("Corporate.title"), t("Corporate.service-info-1"), t("Corporate.service-info-2")]
 var rethical = [t("Corporate.rethical-title"), t("Corporate.rethical-bullet-1"), t("Corporate.rethical-bullet-2"), t("Corporate.rethical-bullet-3"), t("Corporate.rethical-bullet-4"), t("Corporate.rethical-bullet-5")] 
 return (
    <div className="corporate-service-container">

      {/* Title section */}
      <Corporate_title t={titleTranslations}/>

      {/* Rethical Section */}
      <Corporate_rethical t={rethical}/>

      {/* Results Section */}
      <Service_Results service_type={"Corporate"} translations={resultsTranslations}/>

      {/* Methods Section */}
      <Service_methods/>

      {/* Testimonial Section */}
      <Service_testimonial service_type={"Corporate"}/>

    </div>
  )
}
