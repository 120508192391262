import React, { useEffect } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';

const Corporate_title = ({t}) => {

const [ref, inView] = useInView({ triggerOnce: true });
const [ref1, inView1] = useInView({ triggerOnce: true });
const controlbgImage = useAnimation();  
const controlHeading = useAnimation();  
const info = useAnimation();  
const infoColored = useAnimation();  

useEffect(() => {
    if(inView) {
        controlbgImage.start({
            x:0,
            opacity:1,
            transition:{duration:1, type:"spring"}
        });
        controlHeading.start({
            y:0,
            opacity:1,
            transition:{delay:0.5, duration:1, type:"spring"}
        });
        info.start({
            y:0,
            opacity:1,
            transition:{delay:1, duration:1, type:"spring"}
        });
    }
    if(inView1)
    {
        infoColored.start({
            y:0,
            opacity:1,
            transition:{duration:1, type:"spring"}
        });
    }
}, [controlbgImage, controlHeading, info, infoColored, inView, inView1]);

  return (
    <div>
          <div className="title-container relative pt-20">
        <div className="bg-image-div relative pr-52">
          <motion.img 
          ref={ref}
          initial={{ opacity:0, x:-350 }}
          animate={controlbgImage}
          src="/images/service-1-title-pic.png"/>
          <motion.div 
          ref={ref}
          initial={{ opacity:0, y:-50 }}
          animate={controlHeading}
          className="title-service-div purple-bg absolute mr-52">
          <div className="title-service-div-image">
            <img src="/images/service-1-logo.png"/>
          </div>
          <h1>{t[0]}</h1>
        </motion.div>
        </div>
        <motion.div 
        ref={ref}
        initial={{ opacity:0, y:-100 }}
        animate={info}
        className="service-info-div">
          <p>{t[1]}</p>
        </motion.div>
        <motion.div 
        ref={ref1}
        initial={{ opacity:0, y:100 }}
        animate={infoColored} className="service-info-div-2 purple-bg">
        <p>{t[2]}</p>
        </motion.div>
      </div>
    </div>
  )
}

export default Corporate_title
